import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../assets/Button";
import { ReactComponent as I } from "../../images/blogfooter.svg";
import { formatString } from "../../util/util";
const BlogsFooter = () => {
  const [bubble, setBubble] = useState(false);
  const navigate = useNavigate();

  const contactFormFields = [
    { name: "name", displayName: "Full Name" },
    {
      name: "email",
    },
    {
      name: "phone",
    },
    // {
    //   name: "location",
    // },
    { name: "message", type: "textarea", displayName: "Write your message.." },
  ];
  const submitHandler = () => {
    navigate("/recommendation-success");
  };
  return (
    <StyledFooter>
      <I />
      <h1>Can’t find a topic of your interest?</h1>
      <h1 className="fw400 desktop-only">We want to help YOU</h1>
      <p className="fs30 fs14m">
        Send us ideas for our next blog posts and stay tuned for simple
        informative content
      </p>
      {!bubble && (
        <Button onClick={() => setBubble(true)}>Send a recommendation</Button>
      )}
      {bubble && (
        <div className="rec-bubble">
          <form onSubmit={submitHandler} className="fs27" id="c-form">
            {contactFormFields.map((field) =>
              field.type === "textarea" ? (
                <textarea
                  required
                  name={field.name}
                  placeholder={field.displayName || formatString(field.name)}
                ></textarea>
              ) : (
                <input
                  required
                  type="text"
                  name={field.name}
                  placeholder={field.displayName || formatString(field.name)}
                />
              )
            )}
            <Button type="submit">Submit</Button>
          </form>
        </div>
      )}
    </StyledFooter>
  );
};
const StyledFooter = styled.div`
  padding: 4.9vw 0 2vw 11.9vw;
  min-height: 33.23vw;
  background: white;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 6.4vw;
    height: auto;
  }
  h1 {
    font-size: 2.86vw;
    line-height: 3.7vw;
    @media (max-width: 768px) {
      font-size: 8.5vw;
      line-height: 11vw;
    }
  }
  p {
    margin-top: 1.6vw;
    width: 23.8vw;
    @media (max-width: 768px) {
      width: auto;
      margin-top: 6vw;
    }
  }
  svg {
    width: 30.7vw;
    height: 27.16vw;
    position: absolute;
    top: 7.7vw;
    right: 4.8vw;
    @media (max-width: 768px) {
      position: static;
      width: 100%;
      height: 37vw;
      object-fit: contain;
      margin-bottom: 6vw;
    }
  }
  button {
    margin-top: 1.5vw;
    @media (max-width: 768px) {
      margin-top: 7vw;
      width: 100%;
      margin-bottom: 30vw;
    }
  }
  .rec-bubble {
    width: 37vw;
    height: 33.6vw;
    background: white;
    margin-top: 2vw;
    input,
    textarea {
      height: 3.44vw;
      width: 100%;
      border: none;
      outline: none;
      margin-bottom: 0.9vw;
      box-shadow: 0 0.26vw 1.04vw rgba(0, 0, 0, 0.16);
      padding-left: 2.6vw;
      @media (max-width: 768px) {
        height: 12vw;
        margin-bottom: 4vw;
      }
    }
    textarea {
      height: 12.3vw;
      padding-top: 1vw;
      @media (max-width: 768px) {
        height: 24vw;
      }
    }
  }
`;

export default BlogsFooter;
