import React from "react";
import { ReactComponent as Illustration } from "../../images/spreeillustration.svg";
import styled from "styled-components";
import Button from "../assets/Button";
import ProjectCard from "../assets/ProjectCard";
import handshake from "../../images/handshake.png";
import { ReactComponent as SU } from "../../images/we-work-with-all/su.svg";
import { ReactComponent as Agencies } from "../../images/we-work-with-all/agencies.svg";
import { ReactComponent as Enter } from "../../images/we-work-with-all/enter.svg";
import { ReactComponent as SM } from "../../images/we-work-with-all/smallandmed.svg";
import EntityCard from "../assets/EntityCard";
import { Link } from "react-router-dom";
import ProjectsCarousel from "./ProjectsCarousel";

const Projects = () => {
  const entities = [
    { name: "Start Up Business", image: SU },
    { name: "Small & Medium Business", image: SM },
    { name: "Enterprise", image: Enter },
    { name: "Agencies", image: Agencies },
  ];
  return (
    <StyledSection>
      <div className="projects-container">
        <div className="col1">
          <Illustration className="illustration-p" />
          <h1 className="section-heading-projects fs67 fs32m fw700">
            Projects
          </h1>
          <p className="fs30 fs14m projects-paragraph">
            Spree aims to offer a wide tailored variety of solutions. Not only
            is Spree a software company that you hire to do the job, but it is
            also your digital partner that will create all possible solutions to
            help your business grow.
            <br />
            Explore and discover our wide range of diverse projects that our
            team proudly fulfilled with the best solutions. We make every
            project feel personal because our clients matter.
          </p>
          <Button>
            <Link to="/projects">Explore More</Link>
          </Button>
        </div>
        <ProjectsCarousel className="desktop-only" />
        {/* <div className="col2">
          <div className="col2-1">
            <ProjectCard />
            <ProjectCard />
          </div>
          <div className="col2-2">
            <ProjectCard />
            <ProjectCard />
          </div>
        </div> */}
      </div>
      <div className="we-work-container">
        <div className="col1">
          <h1 className="fs67 fs32m fw700">We work with all</h1>
          <p className=" we-work-parag fs30 fs14m">
            Spree has a strong business background team that can integrate all
            aspects to produce the best solutions for all types of companies and
            business sizes. Our professional team can execute all
            large/small-scale projects whether you have a big project in mind or
            even a simple idea, our professional team can execute all
            large/small-scale projects.
          </p>
        </div>
        <div className="col2">
          <div className="work-with-grid">
            {entities.map((entity) => (
              <EntityCard
                key={Math.random(23342)}
                name={entity.name}
                image={entity.image}
              />
            ))}
          </div>
        </div>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  padding: 13vw 8.5vw 6.5vw;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 10vw 6.4vw 6.5vw;
  }
  .projects-container {
    display: flex;
    gap: 5.67vw;
    @media (max-width: 768px) {
      gap: 0;
    }
    .col1 {
      width: 39.5vw;
      @media (max-width: 768px) {
        width: auto;
        display: flex;
        flex-direction: column;
      }
    }
    .illustration-p {
      width: 9.4vw;
      height: auto;
      /* margin-top: 11.04vw; */
      @media (max-width: 768px) {
        display: none;
      }
    }
    .section-heading-projects {
      margin-top: 2.6vw;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    .projects-paragraph {
      margin-top: 1.15vw;
      @media (max-width: 768px) {
        margin-top: 4.3vw;
      }
    }
    button {
      margin-top: 2vw;
      @media (max-width: 768px) {
        margin: auto;
        margin-top: 13.6vw;
      }
    }
    .col2 {
      display: flex;
      gap: 1.8vw;
      @media (max-width: 768px) {
        display: none;
      }
      .col2-1,
      .col2-2 {
        display: flex;
        flex-direction: column;
        gap: 1.96vw;
      }
      .col2-2 {
        margin-top: 3.26vw;
      }
    }
  }
  .we-work-container {
    margin-top: 7.4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      margin-top: 10.7vw;
      flex-direction: column;
      gap: 13.6vw;
    }
    .we-work-parag {
      width: 36vw;
      margin-top: 0.68vw;
      @media (max-width: 768px) {
        width: auto;
        margin-top: 4.3vw;
      }
    }
    .work-with-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2.16vw 2.42vw;
      @media (max-width: 768px) {
        grid-gap: 6.1vw 8.7vw;
      }
    }
  }
`;
export default Projects;
