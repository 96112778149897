import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/logo.png";
import colors from "../../util/colors";
import { ReactComponent as SeeMore } from "../../images/blogseemore.svg";
import { projects } from "../projects/ProjectsContainer";

const ProjectsCarousel = ({ ...props }) => {
  const [selected, setSelected] = useState(0);
  return (
    <StyledCarousel {...props}>
      <div className="overview-section">
        <div className="carousel-col1">
          <img src={projects[selected].logo} alt="" />
        </div>
        <div className="carousel-col2">
          <h1 className="fs38">{projects[selected].client}</h1>
          <p className="fs23">{projects[selected].date}</p>
          <p className="fs23 project-text">{projects[selected].text}</p>
        </div>
        <div className="bullets">
          {projects.map((p, index) => (
            <div
              key={p.client}
              className={`bullet ${selected === index ? "active" : ""}`}
              onClick={() => setSelected(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className="images-section">
        <img src={projects[selected].screenshot} className="images-container" />
      </div>
      <div className="actions-section">
        <Link to={projects[selected].link} className="project-link fs23">
          View Project
          <SeeMore />
        </Link>
        <a
          target="_blank"
          href={projects[selected].website}
          className="project-link fs23"
        >
          Visit website
          <SeeMore />
        </a>
      </div>
    </StyledCarousel>
  );
};

const StyledCarousel = styled.div`
  position: absolute;
  right: -3.8vw;
  top: 11vw;
  background: ${colors.fadedBlue};
  width: 49.1vw;
  height: 50.8vw;
  padding-top: 3.54vw;
  padding-bottom: 2.13vw;
  border-radius: 2.5vw;
  .overview-section {
    display: flex;
    gap: 2.14vw;
    padding: 0 8.7vw 0 5vw;
    align-items: flex-start;
    img {
      background: white;
      width: 7.76vw;
      height: 7.76vw;
      border-radius: 50%;
      object-fit: contain;
      border: solid 0.05vw #707070;
    }
    .carousel-col2 {
      width: 15.3vw;
      .project-text {
        width: 100%;
        height: 10vw;
        overflow: auto;
        word-wrap: break-word;
        margin-top: 1vw;
      }
    }
    .bullets {
      display: flex;
      gap: 1vw;
      .bullet {
        border: solid 0.05vw #ea9c21;
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 50%;
        transition: background 0.5s ease;
        &.active {
          background: #ea9c21;
        }
      }
    }
  }
  .images-section {
    padding-right: 10.17vw;
    padding-left: 5.6vw;
    margin-top: 2.45vw;
    .images-container {
      height: 19.7vw;
      width: 100%;
      object-fit: contain;
    }
  }
  .actions-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 10.7vw;
    padding-left: 6.5vw;
    margin-top: 2.9vw;
    .project-link {
      display: flex;
      align-items: center;
      gap: 1vw;
      color: #ea9c21;
      svg {
        width: 2.8vw;
        height: 2.8vw;
      }
    }
  }
`;

export default ProjectsCarousel;
