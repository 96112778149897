import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import styled from "styled-components";
import ScrollAnimate from "./animations/ScrollAnimate";
import { ReactComponent as Right } from "../../images/right.svg";
import { ReactComponent as Left } from "../../images/left.svg";

const ServicesCarousel = ({ services, svgImages, briefView, ...props }) => {
  const [displayServices, setDisplayServices] = useState(services);
  const [active, setActive] = useState(Math.floor(displayServices.length / 2));
  const previous = () => {
    if (active > 0) {
      setActive(active - 1);
    } else {
      setActive(displayServices.length - 1);
    }
  };
  const next = () => {
    if (active < displayServices.length - 1) {
      setActive(active + 1);
    } else {
      setActive(0);
    }
  };
  useEffect(() => {
    if (displayServices.length - active < 3) {
      setDisplayServices((prev) => [...prev, ...services]);
    }
  }, [active, services]);
  return (
    <ScrollAnimate>
      <CarouselContainer {...props}>
        <Left className="direction-svg left" onClick={previous} />
        <StyledCarousel className="services-container" active={active}>
          {displayServices.map((service, index) => (
            <ServiceCard
              svgImages={svgImages}
              briefView={briefView}
              onClick={() => setActive(index)}
              key={service.name + index}
              style={{
                opacity: 1 - 0.3 * Math.abs(active - index),
                transform: `scale(${1 - 0.08 * Math.abs(active - index)})`,
              }}
              service={service}
            />
          ))}
        </StyledCarousel>
        <Right className="direction-svg right" onClick={next} />
      </CarouselContainer>
    </ScrollAnimate>
  );
};
const StyledCarousel = styled.div`
  /* width: 100vw; */
  position: relative;
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  width: fit-content;
  margin-left: calc(50% - 12.125vw);
  transform: translateX(${(props) => `${-props.active * 24.22}vw`});
  display: flex;
  gap: 1.22vw;
`;

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  .direction-svg {
    position: absolute;
    z-index: 1000;
    top: 50%;
    transform: translateY(-50%);
    width: 2vw;
    cursor: pointer;
    &.left {
      left: 3vw;
    }
    &.right {
      right: 3vw;
    }
  }
`;

export default ServicesCarousel;
