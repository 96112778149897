import React from "react";
import styled from "styled-components";
import ContactUs from "../components/home/ContactUs";
import colors from "../util/colors";
const Contact = () => {
  return (
    <StyledPage>
      <ContactUs />
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .contact-us-form-container {
    background-color: #f2f1f7;
    h1,
    p {
      color: ${colors.mainBlue};
      z-index: 3;
    }
    svg path {
      fill: #f3a51c;
    }
  }
`;
export default Contact;
