import React, { useState } from "react";
import styled from "styled-components";
import QuotationForm from "../components/quotation/QuotationForm";
import QuotationHeader from "../components/quotation/QuotationHeader";
import Success from "./Success";

const Quotation = () => {
  const [success, setSuccess] = useState(false);
  return success ? (
    <Success
      title={"Get a free Quotation"}
      instructions="We will get back to you via email and text as soon as we review your form"
    />
  ) : (
    <div>
      <QuotationHeader />
      <QuotationForm success={success} setSuccess={setSuccess} />
    </div>
  );
};

export default Quotation;
