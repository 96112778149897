import React from "react";
import styled from "styled-components";
import blogbg from "../../images/blogbg.jpg";
import uiuxbg from "../../images/blogs/uiux1.jpg";
import BlogPostCard from "../assets/BlogPostCard";
import Button from "../assets/Button";
import { ReactComponent as Search } from "../../images/icons/search.svg";

export const blogPosts = [
  {
    title: "UI vs UX",
    text: "Are you curious about UI and UX design and keen to learn more? Whether or not UI and UX design are a mystery to you, people have different perspectives whether there are differences or if they are both parts of one another. Here’s a look into the essence of both disciplines.",
    image: uiuxbg,
    link: "/blog/ui-ux",
  },
  {
    title: "Why your business should go online?",
    text: "In the digital age, your business needs to have an online presence. Whether it’s a website, an e-commerce platform, a social media page, or a combination of all three, getting your company online will reap major benefits. Even if your company does not conduct business online, customers and potential customers are expecting to see you online. If they don’t see you there, you could be losing out on the opportunity to increase your customer base and get the word out about your business.",
    image: uiuxbg,
    link: "/blog/why-your-business-should-go-online",
  },
];
const BlogPosts = ({ selectedCategory }) => {
  return (
    <StyledSection>
      <h1 className="fs60 fs16m algcenter-do">{selectedCategory.name}</h1>
      <div className="blog-filters">
        <select className="fs20 fs14m" defaultValue={""}>
          <option value="" disabled>
            Sort by
          </option>
          {/* <option value="#">option</option>
          <option value="#">option</option>
          <option value="#">option</option> */}
        </select>
        <form>
          <Search />
          <input
            className="fs20 fs14m"
            type="text"
            placeholder="Search our blog.."
          />
        </form>
      </div>
      {blogPosts.length > 0 ? (
        <div className="blog-posts-container-page">
          {blogPosts.map((post) => (
            <BlogPostCard
              key={Math.random(17387232)}
              title={post.title}
              text={post.text}
              // image={post.image}
              link={post.link}
              screenshot={post.image}
            />
          ))}
        </div>
      ) : (
        <p
          className="no-posts fs20 fs16m fw700 algcenter"
          style={{ marginTop: "2vw", opacity: "0.7" }}
        >
          No blog posts yet
        </p>
      )}
      {/* <div className=" fs30 blogs-pagination">
        <p>Showing 6 of 36</p>
        <Button>Load more</Button>
      </div> */}
    </StyledSection>
  );
};
const StyledSection = styled.div`
  background: #f2f1f7;
  padding: 3.16vw 0vw 2.7vw 7.6vw;
  min-height: 60vh;
  @media (max-width: 768px) {
    padding: 11.5vw 6.4vw;
    background-color: white;
  }
  .blog-filters {
    margin-top: 1.3vw;
    display: flex;
    gap: 2.3vw;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
    }
    form {
      position: relative;
      svg {
        position: absolute;
        right: 1.32vw;
        top: 50%;
        transform: translateY(-50%);
        width: 1.6vw;
        height: 1.77vw;
        @media (max-width: 768px) {
          width: 5vw;
          height: 6vw;
          right: 3vw;
        }
      }
    }
    select,
    input {
      display: block;
      padding-left: 1.6vw;
      width: 17.29vw;
      height: 3.02vw;
      border-radius: 0.8vw;
      outline: none;
      border: solid 0.05vw #707070;
      @media (max-width: 768px) {
        width: auto;
        flex: 1;
        height: 11.7vw;
        border-radius: 1.5vw;
      }
      &::placeholder {
        color: #c4c2cb;
      }
    }
  }
  .blog-posts-container-page {
    margin-top: 4.2vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4vw;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 6.9vw;
    }
  }
  .blogs-pagination {
    margin: auto;
    width: fit-content;
    text-align: center;
    margin-top: 3.45vw;
  }
`;
export default BlogPosts;
