import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import GlobalPage from "./components/assets/GlobalPage";
import Footer from "./components/home/Footer";
import Nav from "./components/home/Nav";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Careers from "./pages/Careers";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Quotation from "./pages/Quotation";
import Services from "./pages/Services";
import { AnimatePresence } from "framer-motion";
import ScrollTop from "./components/assets/ScrollTop";
import ScrollBtn from "./components/assets/ScrollBtn";
import Contact from "./pages/Contact";
import ApplicationForm from "./pages/ApplicationForm";
import Equity from "./pages/projects/Equity";
import TheNileAnchor from "./pages/projects/TheNileAnchor";
import Success from "./pages/Success";
import UiUx from "./pages/blog-posts/UiUx";
import WhyWebsite from "./pages/blog-posts/WhyWebsite";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Nav />
      <ScrollTop />
      <ScrollBtn />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes path="/" location={location} key={location.pathname}>
          <Route path="/" element={<GlobalPage />}>
            <Route index element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/about" element={<About />} />
            <Route path="/get-quotation" element={<Quotation />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/ui-ux" element={<UiUx />} />
            <Route
              path="/blog/why-your-business-should-go-online"
              element={<WhyWebsite />}
            />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/equity" element={<Equity />} />
            <Route path="/projects/thenileanchor" element={<TheNileAnchor />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/apply/:id" element={<ApplicationForm />} />
            <Route
              path="/phone-call-request-success"
              element={<Success title="Request a phone call" />}
            />
            <Route
              path="/newsletter-request-success"
              element={<Success title="Subscribe to our Newsletter" />}
            />
            <Route
              path="/recommendation-success"
              element={
                <Success
                  instructions={
                    "WE ARE WORKING ON DELIVERING CONTENT THAT WILL HELP YOU, STAY TUNED FOR OUR WEEKLY POSTS "
                  }
                />
              }
            />
          </Route>
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
