import React from "react";
import styled from "styled-components";
import Button from "../assets/Button";
import { ReactComponent as Illustration } from "../../images/careersillustration.svg";
import { ReactComponent as Illustration2 } from "../../images/icons/switch.svg";

const CareersHeader = () => {
  return (
    <StyledSection>
      <Illustration className="svg1" />
      <Illustration2 className="svg2" />
      <h1 className="htitle">WE ARE HIRING!</h1>
      <p className="fs30 fs14m">JOIN THE SPREE FAMILY TODAY</p>
      <p className="fs30 fs14m"></p>
      {/* <Button>VIEW JOBS</Button> */}
    </StyledSection>
  );
};

export const CareersFooter = () => {
  return (
    <StyledFooter className="algcenter">
      <h1 className="footer-careers-h ">We are constantly expanding!</h1>
      <h2 className="fs47 fs14m fw400">Can’t find the position for you?</h2>
      <p className="fs30 fs14m algcenter">Check our openings regularly!</p>
      {/* <Button>Send your CV</Button> */}
    </StyledFooter>
  );
};
const StyledSection = styled.div`
  padding: 2.9vw 0 4vw 9.8vw;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 13.9vw 6.4vw 25.9vw;
    align-items: center;
  }
  .svg1 {
    width: 26.6vw;
    height: 35vw;
    position: absolute;
    top: 1.2vw;
    right: 6.2vw;
    @media (max-width: 768px) {
      position: static;
      width: 36.9vw;
      height: 48.49vw;
    }
  }
  .svg2 {
    width: 7.08vw;
    height: 4.9vw;
    position: absolute;
    top: 10.5vw;
    left: 34.2vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .htitle {
    width: 26.7vw;
    font-size: 6.1vw;
    line-height: 6vw;
    @media (max-width: 768px) {
      width: 80.4vw;
      text-align: center;
      font-size: 12.8vw;
      line-height: 13.1vw;
    }
  }
  p {
    margin-bottom: 1.7vw;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 9.3vw 5.7vw;
  @media (max-width: 768px) {
    padding: 20.4vw 6.4vw 33.6vw;
    align-items: flex-start;
    * {
      text-align: left;
    }
  }
  .footer-careers-h {
    font-size: 4.01vw;
    @media (max-width: 768px) {
      font-size: 8.5vw;
    }
  }
  p {
    width: 23.7vw;
    margin: 1.3vw auto;
    @media (max-width: 768px) {
      width: auto;
      margin: 0;
      margin-top: 5vw;
    }
  }
  h2 {
    @media (max-width: 768px) {
      margin-top: 2vw;
    }
  }
  button {
    margin-top: 1.34vw;
    text-align: center;
    align-self: center;
    @media (max-width: 768px) {
      margin-top: 14.6vw;
    }
  }
`;
export default CareersHeader;
