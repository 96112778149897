import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Button from "../assets/Button";
import Line from "../assets/Line";
import { ReactComponent as V } from "../../images/services/confirm.svg";
import { Link } from "react-router-dom";
import ScrollAnimate from "../assets/animations/ScrollAnimate";
const ServicesWhyUs = () => {
  const whyUs = [
    "WE ARE YOUNG",
    "WE ARE FLEXIBLE",
    "WE ARE RELIABLE",
    "WE ARE AVAILABLE",
  ];
  return (
    <StyledSection>
      <div className="s-why-us-header">
        <Line className="desktop-only" />
        <h1 className="fs67 fs18m">
          Why choosing Spree for Web Design is the right Solution?
        </h1>
      </div>
      <div className="s-why-us">
        {whyUs.map((whyUs, i) => (
          <ScrollAnimate delay={0.2 * i} key={whyUs}>
            <div className="s-why-us-card-s">
              <V />
              <h1>{whyUs}</h1>
            </div>
          </ScrollAnimate>
        ))}
      </div>
    </StyledSection>
  );
};

export const WhyUsSubSection = () => {
  return (
    <StyledSubSection>
      <h4 className="fs32 fs14m fw400">Meet our happy partners</h4>
      <h1 className="fs67 fs32m">Explore our projects to find out more</h1>
      <Button>
        <Link to="/projects">More Details</Link>
      </Button>
    </StyledSubSection>
  );
};

const StyledSection = styled.div`
  height: 40.6vw;
  padding-top: 4.7vw;
  padding-left: 4.13vw;
  padding-right: 4.13vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    height: auto;
    padding-top: 20.34vw;
  }
  .s-why-us-header {
    width: 57.7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: auto;
    }
    h1 {
      margin-top: 2vw;
      text-align: center;
    }
  }
  .s-why-us {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4.3vw;
    .s-why-us-card-s {
      h1 {
        color: ${colors.mainYellow};
        font-size: 3.12vw;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.1vw;
      max-width: 17.6;
      font-size: 3.12vw;
      font-weight: 700;
      text-align: center;
      color: ${colors.mainYellow};
      svg {
        max-width: 100%;
        height: 5vw;
        object-fit: contain;
        @media (max-width: 768px) {
          height: 10vw;
        }
      }
    }
  }
`;
const StyledSubSection = styled.div`
  height: 27.4vw;
  background: ${colors.fadedBlue};
  padding-top: 3.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    height: auto;
    background: none;
    padding-top: 17vw;
    padding-left: 6.4vw;
    padding-right: 6.4vw;
    padding-bottom: 25vw;
  }
  h1 {
    width: 44.8vw;
    text-align: center;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  button {
    margin-top: 2.1vw;
    @media (max-width: 768px) {
      margin-top: 9.5vw;
    }
  }
`;
export default ServicesWhyUs;
