import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../util/colors";
import Button from "../assets/Button";

export const jobs = [
  {
    id: 1,
    title: "UI/UX Designer",
    department: "Creative Department",
    location: "New Cairo",
    type: "Full-Time",
  },
  {
    id: 2,
    title: "Front End Developer",
    department: "Creative Department",
    location: "New Cairo",
    type: "Full-Time",
  },
  {
    id: 3,
    title: "Back End Developer",
    department: "Creative Department",
    location: "New Cairo",
    type: "Full-Time",
  },
  {
    id: 4,
    title: "Dev Ops Engineer",
    department: "Creative Department",
    location: "New Cairo",
    type: "Full-Time",
  },
];
const Openings = () => {
  return (
    <StyledSection>
      <h1 className="fs57 fs16m fw700 algcenter">Job Openings</h1>
      <div className="search-filter">
        <input type="text" placeholder="Search jobs ..." />
        <select name="type" id="type">
          <option>Work Type</option>
        </select>
      </div>
      <table>
        <tbody>
          {jobs.map((job) => (
            <tr key={Math.random(32432)} className="fs30">
              <td className="job-title">
                <p className="fs40 fs18m fw700 ">{job.title}</p>
                <p className="fs30 fs14m">{job.department}</p>
                <div className="mobile-job-details mobile-only fs12m">
                  <p>{job.location}</p>
                  <p>{job.type}</p>
                </div>
              </td>
              <td className="job-location algcenter desktop-only">
                {job.location}
              </td>
              <td className="job-type algcenter desktop-only">{job.type}</td>
              <td className="action-cell">
                <Link to={`/apply/${job.id}`}>
                  <Button>Apply Now</Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  background: ${colors.fadedBlue};
  padding: 4.7vw 0 0 7.8vw;
  @media (max-width: 768px) {
    padding: 0 6.4vw;
    background-color: white;
  }
  .search-filter {
    display: flex;
    gap: 2vw;
    margin-top: 2.4vw;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 3.7vw;
    }
    input,
    select {
      padding: 0 2vw;
      height: 3.3vw;
      outline: none;
      border-radius: 0.8vw;
      border: solid 0.05vw #707070;
      @media (max-width: 768px) {
        height: 11.7vw;
        border-radius: 1.6vw;
        border: solid 0.26vw #707070;
      }
    }
    input {
      width: 53vw;
      @media (max-width: 768px) {
        width: auto;
      }
    }
    select {
      width: 30.4vw;
      @media (max-width: 768px) {
        width: auto;
      }
    }
  }
  table {
    width: 85vw;
    border-collapse: collapse;
    @media (max-width: 768px) {
      margin-top: 14.64vw;
    }
    tr {
      @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        height: 40.8vw;
        /* &:not(:first-child) { */
        padding-top: 7vw;
        padding-bottom: 7vw;
        /* } */
      }
      td {
        width: 25%;
        @media (max-width: 768px) {
          width: auto;
        }
        &.action-cell {
          @media (max-width: 768px) {
            button {
              min-width: 37.9vw;
            }
          }
        }
        .mobile-job-details {
          @media (max-width: 768px) {
            display: flex;
            gap: 3.2vw;
            margin-top: 5vw;
          }
        }
      }
      height: 10.6vw;
      /* padding: 2.44vw 0 1.6vw 2.1vw; */
      &:not(:first-child) {
        border-top: solid 0.05vw #707070;
      }
    }
  }
`;
export default Openings;
