import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Illustration } from "../../images/quoillustration.svg";
import Button from "../assets/Button";
const Quotation = () => {
  return (
    <StyledSection>
      <Illustration />
      <h1 className="fs67 fs32m fw700">Request a free quotation today!</h1>
      <p className="fs27 fs14m">
        Let us know your needs and we will provide top notch solutions for them
        all
      </p>
      <Button>
        <Link to="/get-quotation">Get a free quotation!</Link>
      </Button>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  height: 31.2vw;
  background: #f2f1f7;
  position: relative;
  padding: 8.9vw 0 0 13.65vw;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 22.9vw 6.4vw 18.13vw;
    h1 {
      margin-bottom: 4.3vw;
    }
  }
  p {
    margin-bottom: 1.875vw;
    @media (max-width: 768px) {
      margin-bottom: 10.7vw;
    }
  }
  svg {
    position: absolute;
    right: 11.4vw;
    top: 0;
    width: 15.8vw;
    height: 26.8vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export default Quotation;
