import React, { useState } from "react";
import CareersHeader from "../components/careers/CareersHeader";
import styled from "styled-components";
import colors from "../util/colors";
import { jobs } from "../components/careers/Openings";
import { useParams } from "react-router-dom";
import Success from "./Success";
const ApplicationForm = () => {
  const { id } = useParams();
  const [info, setInfo] = useState({});
  const [job, setJob] = useState(jobs.filter((j) => j.id === parseInt(id))[0]);
  const [success, setSuccess] = useState(false);
  return success ? (
    <Success
      title="WE ARE HIRING"
      instructions="We will get back to you via email and text as soon as we review your form"
    />
  ) : (
    <StyledPage>
      <CareersHeader />
      <div className="form-container">
        <h1 className="job-title">{job.title}</h1>
        <h4 className="job-type">{job.type}</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSuccess(true);
          }}
        >
          <div className="grid1">
            <input
              type="text"
              name="fullname"
              placeholder="Full Name*"
              required
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="email"
              name="email"
              className="double-span"
              placeholder="Email*"
              required
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="text"
              name="phone"
              placeholder="Phone*"
              required
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="text"
              name="address"
              placeholder="Address*"
              required
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="text"
              name="current_position"
              placeholder="Current Position (if applicable)"
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="text"
              name="education"
              placeholder="Education*"
              required
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="text"
              name="certefications"
              placeholder="Certifications (if applicable)"
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />

            <input
              type="text"
              name="experience"
              placeholder="Years of Experience*"
              required
              onChange={(e) =>
                setInfo({ ...info, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="grid2">
            <div className="grid2-item">
              <label htmlFor="resume" className="fw700m">
                Resume
              </label>
              <button type="none" className="btn">
                Upload a file
              </button>
              <input type="file" name="resume" id="resume" />
            </div>
            <div className="grid2-item">
              <label htmlFor="portfolio" className="fw700m">
                Portfolio (if available)
              </label>
              <button type="none" className="btn">
                Upload a file
              </button>
              <input type="file" name="portfolio" id="portfolio" />
            </div>
          </div>
          <div className="grid3">
            <div className="grid3-item">
              <label htmlFor="brief" className="fw700m">
                We want to get to know you!
              </label>
              <textarea
                name="brief"
                id="brief"
                cols="30"
                rows="10"
                placeholder="Brief about yourself"
              ></textarea>
            </div>
            <div className="grid3-item">
              <label htmlFor="reason" className="fw700m">
                Reasons for leaving previous job
              </label>
              <textarea
                name="reason"
                id="reason"
                cols="30"
                rows="10"
                placeholder="Brief about yourself"
              ></textarea>
            </div>
          </div>
          <button className="submit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .form-container {
    padding: 2.7vw 6.2vw 6.4vw 7.76vw;
    min-height: 50vh;
    background-color: ${colors.fadedBlue};
    .submit {
      width: 13.3vw;
      height: 3.5vw;
      border-radius: 0.8vw;
      color: white;
      font-size: 1.2vw;
      font-weight: 700;
      background: ${colors.mainYellow};
      border: none;
      margin-top: 3vw;
      @media (max-width: 768px) {
        width: 100%;
        height: 11.7vw;
        font-size: 3.7vw;
        border-radius: 4vw;
        margin-top: 12.8vw;
      }
    }
    h1,
    h4 {
      text-align: center;
    }
    .grid1 {
      margin-top: 1vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.3vw;
      row-gap: 0.9vw;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 4.3vw;
      }
      .double-span {
        grid-column: span 2;
      }
      input {
        font-size: 1vw;
        height: 3.3vw;
        padding: 0 1vw;
        border-radius: 0.8vw;
        border: solid 0.08vw #707070;
        @media (max-width: 768px) {
          font-size: 3.7vw;
          height: 11.7vw;
          border-radius: 1.6vw;
          border: solid 0.27vw #707070;
          padding: 0 3vw;
        }
      }
    }
    .grid2 {
      margin-top: 2vw;
      display: flex;
      gap: 10vw;
      @media (max-width: 768px) {
        margin-top: 6vw;
        flex-direction: column;
        /* align-items: center; */
      }
      .grid2-item {
        position: relative;
        overflow: hidden;
        display: inline-block;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5vw;
        @media (max-width: 768px) {
          gap: 3vw;
          /* align-items: center; */
        }
      }

      .btn {
        width: 14.8vw;
        height: 3.5vw;
        color: white;
        background-color: #b4b3b5;
        border-radius: 0.8vw;
        font-size: 1vw;
        font-weight: bold;
        border: none;
        @media (max-width: 768px) {
          width: 35.2vw;
          height: 11.7vw;
          border-radius: 4vw;
          font-size: 3.7vw;
        }
      }

      .grid2-item input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
    .grid3 {
      margin-top: 2vw;
      display: flex;
      flex-direction: column;
      gap: 2vw;
      @media (max-width: 768px) {
        margin-top: 6vw;
      }
      .grid3-item {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        textarea {
          border-radius: 0.8vw;
          border: solid 0.08vw #707070;
          padding: 1vw;
          font-size: 1vw;
          @media (max-width: 768px) {
            border-radius: 2vw;
            height: 50vw;
            font-size: 3.7vw;
            padding: 3vw;
          }
        }
      }
    }
  }
`;
export default ApplicationForm;
