import React from "react";
import { formatString } from "../../util/util";
import styled from "styled-components";
const CheckSelect = ({ field }) => {
  return (
    <Check>
      <label className="fs30 fs16m fw700" htmlFor={field.name}>
        {field.displayName || formatString(field.name)}
        {field.required !== false && "*"}
      </label>
      {field.caption && <p className="fs30 fs14m caption">{field.caption}</p>}
      <div className="cbs">
        {field.options.map((o) => (
          <div className="cb-group">
            <input
              name={field.name}
              id={field.name + o.name}
              type={field.type === "radio" ? "radio" : "checkbox"}
            />
            <label className="fs30 fs14m" htmlFor={field.name + o.name}>
              {o.name}
            </label>
          </div>
        ))}
      </div>
    </Check>
  );
};
const Check = styled.div`
  .caption {
    color: #c4c2cb;
  }
  .cbs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 2.9vw;
    }
    .cb-group {
      display: flex;
      gap: 1vw;
      align-items: center;
      @media (max-width: 768px) {
        gap: 2.3vw;
      }
    }
  }
`;
export default CheckSelect;
