import React from "react";
import styled from "styled-components";
const ServicesListCard = ({ name, image: Image, ...props }) => {
  return (
    <StyledCard {...props}>
      <Image />
      <div className="service-card-text">
        <p className="fs18 fs14m fw700 algcenter">{name}</p>
      </div>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #f2f1f7;
  width: 8.9vw;
  height: 9.4vw;
  border-radius: 0.57vw;
  box-shadow: 0.52vw 0.68vw 0.833vw rgba(0, 0, 0, 0.16);
  padding: 0.73vw 0.45vw 1.2vw;
  outline: ${(props) => (props.selected ? "solid 0.2vw #f3a51c" : "none")};
  cursor: pointer;
  @media (max-width: 768px) {
    width: 41.8vw;
    height: 43.8vw;
    border-radius: 2.9vw;
    outline: ${(props) => (props.selected ? "solid 1vw #f3a51c" : "none")};
    align-items: center;
    padding-bottom: 6.8vw;
  }
  .service-card-text {
    height: fit-content;
    min-height: 3.15vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media (max-width: 768px) {
      width: 75%;
    }
  }
  svg {
    width: 100%;
    flex: 1;
    @media (max-width: 768px) {
      width: 50%;
    }
  }
`;
export default ServicesListCard;
