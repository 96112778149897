import React from "react";
import styled from "styled-components";
import logo from "../../images/logo.png";
const ReviewCard = () => {
  return (
    <StyledCard className="review-card">
      <img src={logo} alt="review-logo" />
      <h1 className="fs25">client name</h1>
      <div className="stars-container">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
      </div>
      <h3 className="review-title fs16">main comments</h3>
      <p className="review-content fs16">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
        rerum excepturi id reprehenderit numquam ipsum, atque, ab cum, molestiae
        tempora consequuntur. Explicabo, dolor suscipit ratione assumenda neque
        blanditiis doloribus ipsa!
      </p>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  width: 20.8vw;
  height: 24.2vw;
  background: white;
  border-radius: 0.57vw;
  padding: 1vw 1.2vw;
  img {
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 1.3vw;
    background: #cecece;
  }
  p,
  h3 {
    margin-top: 1vw;
  }
  .stars-container {
    margin-top: 1vw;
    width: calc(1.8vw * 5);
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 0.3vw;
    .star {
      min-width: 1.5vw;
      height: 1.5vw;
      border-radius: 50%;
      background: yellow;
    }
  }
  @media (max-width: 768px) {
    width: auto;
    height: 106vw;
    border-radius: 2.9vw;
  }
`;
export default ReviewCard;
