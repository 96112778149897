import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/projects/nalogo.png";
import wiref from "../../images/projects/wiref.jpg";
import screensh from "../../images/projects/nascreensh.jpg";
import { ReactComponent as Lamp } from "../../images/projects/lamp.svg";
import { ReactComponent as SeeMore } from "../../images/blogseemore.svg";
import colors from "../../util/colors";
import sketch1 from "../../images/projects/naillust0.jpg";
import sketch2 from "../../images/projects/naillust12.jpg";
import illust1 from "../../images/projects/na-illlust.png";
import illust2 from "../../images/projects/Nileanchor1.png";
const TheNileAnchor = () => {
  return (
    <StyledProject>
      <Link className="fs16 fs12m" to="/projects">
        {"< Back to All Projects"}
      </Link>
      <div className="project-header">
        <img src={logo} alt="project-image" className="project-image" />
        <div className="header-container">
          <h1 className="fs60 fs28m">The Nile Anchor</h1>
          <div className="launch-date fs30 fs12m">
            <p>Launch Date</p>
            <p>02/07/2021</p>
          </div>
        </div>
      </div>
      <div className="section-1">
        <h3 className="section-heading">Project Brief</h3>
        <div className="section-1-content">
          <p className="fs32 fs14m">
            - Client Web App
            <br />- Admin Management Console
          </p>
          <img src={screensh} alt="equity-screenshot" />
        </div>
      </div>
      <div className="section-2">
        <h3 className="section-heading">Wireframing</h3>
        <div className="section-2-content">
          <div className="images">
            <img src={sketch1} alt="wire-framing" />
            <img src={sketch2} alt="wire-framing" />
          </div>
          <div className="section-2-text fs23 fs14m">
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi sit
              ipsa distinctio eum eos? Dolorem saepe, ea amet nemo asperiores
              quisquam temporibus, nisi voluptates sunt nobis doloribus
              laudantium architecto deleniti labore aliquam, at similique illo
              cupiditate. Quidem perferendis inventore qui rerum at nihil,
              itaque sequi hic quas. Tenetur, magni est.
            </p> */}
            <h3 className="section-heading">Technical Challenges</h3>
            <p>
              - Integration with NileAnchor ERP System
              <br />
              - Reservation system real-time sync
              <br />
              - Security
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="section-3">
        <h3 className="section-heading">Our Solution</h3>
        <div className="section-3-text fs23 fs14m">
          <p>
            - Developed a Company profile website <br />- A full reservation
            System (Availability - Reservation – Payment)
          </p>
          <Lamp />
        </div>
      </div>
      <div className="cta-section">
        <img className="cta-illust" src={illust2} alt="" />
        <h3>Wanna see the end result ?</h3>
        <a
          className="fs32 fs14m fw700"
          target="_blank"
          href="http://thenileanchor.com"
        >
          Visit Website
          <SeeMore />
        </a>
        <img className="content-photo" src={illust1} alt="" />
      </div>
    </StyledProject>
  );
};

const StyledProject = styled.div`
  padding: 4vw 6.4vw 6.5vw 6.4vw;
  position: relative;
  .content-photo {
    position: absolute;
    left: 0%;
    bottom: 0;
    width: 40vw;
    height: 30vw;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 30vw;
      height: auto;
    }
  }
  .section-heading {
    font-size: 2.13vw;
    margin-bottom: 1vw;
    @media (max-width: 768px) {
      font-size: 4.3vw;
    }
  }
  .project-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 768px) {
      flex-direction: row;
      margin: 8vw 0;
      gap: 6.4vw;
    }
    img {
      width: 7.7vw;
      height: 7.7vw;
      border-radius: 50%;
      @media (max-width: 768px) {
        width: 19vw;
        height: 19vw;
      }
    }
    .launch-date {
      display: flex;
      gap: 1vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      @media (max-width: 768px) {
        position: static;
        flex-direction: column;
        transform: none;
      }
    }
  }
  .section-1 {
    .section-1-content {
      display: flex;
      gap: 2vw;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      img {
        width: 36.6vw;
        @media (max-width: 768px) {
          width: 100%;
          margin: 8.5vw 0;
        }
      }
    }
  }
  .section-2 {
    .section-2-content {
      display: flex;
      gap: 3.2vw;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .section-heading {
        margin-top: 1vw;
      }
      img {
        width: 30vw;
        display: block;
        @media (max-width: 768px) {
          width: 80%;
          margin: auto;
        }
      }
      p {
        width: 37vw;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .section-3 {
    margin-top: 7vw;
    .section-heading {
      text-align: center;
      width: 45vw;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .section-3-text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      p {
        width: 45vw;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      svg {
        width: 10vw;
        height: auto;
        @media (max-width: 768px) {
          width: 20vw;
          margin: 8.5vw auto;
        }
      }
    }
  }
  .cta-section {
    /* margin-top: 10vw; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h3 {
      font-size: 2.13vw;
      @media (max-width: 768px) {
        font-size: 4.3vw;
        text-align: center;
      }
    }
    a {
      display: flex;
      align-items: center;
      padding: 0 1vw;
      justify-content: space-between;
      width: 18vw;
      height: 4.6vw;
      box-shadow: 0 0.15vw 0.31vw rgba(0, 0, 0, 0.16);
      border-radius: 0.8vw;
      color: ${colors.mainYellow};
      margin-top: 1vw;
      @media (max-width: 768px) {
        width: 60%;
        height: 15vw;
        justify-content: center;
        gap: 7vw;
        box-shadow: 0 0.8vw 1.6vw rgba(0, 0, 0, 0.16);
        margin-top: 7.5vw;
      }
      svg {
        width: 3vw;
        @media (max-width: 768px) {
          width: 10vw;
        }
      }
    }
    .cta-illust {
      width: 80vw;
      width: 30%;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 5vw;
      }
    }
  }
`;

export default TheNileAnchor;
