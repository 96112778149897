import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Line from "../assets/Line";
import { ReactComponent as Image } from "../../images/services/devcycle.svg";
import image from "../../images//services/devcycleimg.jpg";
import BubbleStepper from "../assets/BubbleStepper";
import { ReactComponent as S1 } from "../../images/devcycle/step1.svg";
import { ReactComponent as S2 } from "../../images/devcycle/step2.svg";
import { ReactComponent as S3 } from "../../images/devcycle/step3.svg";
import { ReactComponent as S4 } from "../../images/devcycle/step4.svg";
import { ReactComponent as S5 } from "../../images/devcycle/step5.svg";
import { motion } from "framer-motion";

import s1 from "../../images/devcycle/step1.jpg";
import s2 from "../../images/devcycle/step2.jpg";
import s3 from "../../images/devcycle/step3.jpg";
import s4 from "../../images/devcycle/step4.jpg";
import s5 from "../../images/devcycle/step5.jpg";
import { AnimatePresence } from "framer-motion";

const DevCycles = () => {
  const cycleSteps = [
    { name: "Ideation", illustration: S1, image: s1 },
    { name: "UI/UX Design", illustration: S2, image: s2 },
    { name: "Development", illustration: S3, image: s3 },
    { name: "Testing", illustration: S4, image: s4 },
    { name: "Launch", illustration: S5, image: s5 },
  ];
  const [active, setActive] = useState(0);
  const [vw, setVw] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setVw(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setVw(window.innerWidth);
      });
    };
  }, []);
  return (
    <StyledSection>
      <div className="devcycles">
        <div className="col1">
          <Line className="desktop-only" />
          <h1 className="fs57 fs32m">Development Cycle</h1>
          <AnimatePresence exitBeforeEnter>
            <motion.img
              key={active}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              src={cycleSteps[active].image}
              alt="dev cycle"
            />
          </AnimatePresence>
        </div>
        <div className="col2">
          <BubbleStepper
            items={cycleSteps || []}
            diameter={vw > 768 ? 6.14 : 16.8}
            gap={vw > 768 ? 2.45 : 6.88}
            thickness={vw > 768 ? 0.52 : 2.67}
            active={active}
            setActive={setActive}
          />
        </div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  padding: 4.74vw 0 0 0;
  @media (max-width: 768px) {
    padding: 0;
  }
  .devcycles {
    display: flex;
    gap: 7.14vw;
    padding: 1.8vw 0 0 7.8vw;
    width: 78.1vw;
    height: 49.33vw;
    margin: 0 auto;
    border-radius: 0.57vw;
    box-shadow: 0.52vw 0.68vw 0.833vw rgba(0, 0, 0, 0.16);
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      height: auto;
      box-shadow: none;
      padding: 1.8vw 6.4vw 0;
    }
    .col1 {
      h1 {
        margin-top: 2.01vw;
        @media (max-width: 768px) {
          width: 80%;
        }
      }
      img {
        width: 39.5vw;
        height: 26.4vw;
        margin-top: 2vw;
        @media (max-width: 768px) {
          width: 100%;
          height: auto;
          margin-top: 5.8vw;
        }
      }
    }
    .col2 {
      @media (max-width: 768px) {
        margin-top: 14vw;
      }
      /* svg {
        width: 20.42vw;
        height: 41.39vw;
      } */
    }
  }
`;

export default DevCycles;
