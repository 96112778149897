import React, { useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import { ReactComponent as Search } from "../images/icons/search.svg";
import BlogPosts from "../components/blog/BlogPosts";
import BlogsFooter from "../components/blog/BlogsFooter";
const Blog = () => {
  const [selected, setSelected] = useState(0);
  const filterOptions = [
    { name: "All" },
    { name: "UI/UX Design" },
    { name: "Coding" },
    { name: "E-Commerce" },
    { name: "Graphic Design" },
    { name: "Web Design" },
  ];
  return (
    <StyledPage>
      <div className="header-blog-page">
        <div className="row1">
          <div className="col1">
            <h1>BLOG</h1>
            <h2 className="fw400">WELCOME TO SPREE’S BLOG</h2>
          </div>
          <div className="col2">
            <p className="fs27 fs16m fw700">Subscribe to our Newsletter</p>
            <p className="fs20 fs14m">Never miss a post!</p>
            <form>
              <input
                className="fs20 fs14m"
                type="text"
                placeholder="Email@company.com"
              />
              <button className="fs20 fs14m fw700">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="row2">
          <form>
            <Search />
            <input
              className="fs27 fs14m"
              type="text"
              placeholder="Search our blog.."
            />
          </form>
        </div>
        <div className="filter-blog">
          {filterOptions.map((f, index) => (
            <div
              onClick={() => setSelected(index)}
              className={`${
                selected === index ? "selected" : ""
              } filter-option fs20 fs12m fw700m algcenter-mo`}
              key={f.name}
            >
              {f.name}
            </div>
          ))}
        </div>
      </div>
      <BlogPosts selectedCategory={filterOptions[selected]} />
      <BlogsFooter />
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .header-blog-page {
    height: 24.3vw;
    background: ${colors.mainBlue};
    padding: 3vw 11.8vw 0;
    @media (max-width: 768px) {
      height: auto;
      padding: 19.2vw 6.4vw 14.7vw;
    }

    .row1 {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .col1 {
        h1,
        h2 {
          color: white;
        }
        h1 {
          font-size: 6vw;
          line-height: 6vw;
          @media (max-width: 768px) {
            font-size: 12.8vw;
            line-height: 12.8vw;
          }
        }
        h2 {
          font-size: 2.18vw;
        }
      }
      .col2 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (max-width: 768px) {
          align-items: flex-start;
          margin-top: 12vw;
        }

        p {
          color: white;
        }
        form {
          position: relative;
          margin-top: 0.89vw;
          @media (max-width: 768px) {
            margin-top: 9vw;
            width: 100%;
          }

          input {
            display: block;
            outline: none;
            border: none;
            width: 22.55vw;
            height: 2.8vw;
            border-radius: 0.8vw;
            padding-right: 7.7vw;
            padding-left: 1.4vw;
            @media (max-width: 768px) {
              width: 100%;
              height: 11.7vw;
              border-radius: 1.9vw;
              padding-right: 22vw;
              padding-left: 4vw;
            }
            &::placeholder {
              color: #d9d8db;
            }
          }
          button {
            position: absolute;
            right: 0.52vw;
            top: 50%;
            transform: translateY(-50%);
            color: #932fff;
            background: none;
            border: none;
            outline: none;
            @media (max-width: 768px) {
              right: 1.5vw;
            }
          }
        }
      }
    }
    .row2 {
      margin-top: 1.65vw;
      @media (max-width: 768px) {
        margin-top: 11vw;
      }
      form {
        position: relative;
        @media (max-width: 768px) {
        }
        svg {
          position: absolute;
          right: 1.32vw;
          top: 50%;
          transform: translateY(-50%);
          width: 1.6vw;
          height: 1.77vw;
          @media (max-width: 768px) {
            width: 5vw;
            height: 6vw;
            right: 3vw;
          }
        }
        input {
          outline: none;
          border: none;
          width: 100%;
          height: 3.7vw;
          border-radius: 0.8vw;
          display: block;
          padding-left: 1.4vw;
          padding-right: 4vw;
          @media (max-width: 768px) {
            height: 11.7vw;
            border-radius: 1.9vw;
            padding-left: 4vw;
            padding-right: 10vw;
          }
          &::placeholder {
            color: #d9d8db;
          }
        }
      }
    }
    .filter-blog {
      display: flex;
      width: 100%;
      margin-top: 3.45vw;
      @media (max-width: 768px) {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        gap: 1.9vw;
        overflow: scroll;
        margin-top: 6vw;
      }
      .filter-option {
        background: white;
        width: 11.13vw;
        flex: 1;
        border: solid 0.2vw #f2f1f7;
        height: 3.7vw;
        border-radius: 1vw 1vw 0vw 0vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: 768px) {
          min-width: 21.86vw;
          height: 11.7vw;
          border-radius: 4vw;
        }
        &.selected {
          background: #f2f1f7;
        }
      }
    }
  }
`;
export default Blog;
