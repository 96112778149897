import React from "react";
import styled from "styled-components";
import uiux1 from "../../images/blogs/uiux1.jpg";
import uiux2 from "../../images/blogs/uiux2.jpg";
import uiux3 from "../../images/blogs/uiux3.jpg";

const UiUx = () => {
  return (
    <StyledUU>
      <div className="uu-header">
        <h1 className="fs47 fs32m algcenter">UI vs UX</h1>
        <h1 className="fs23 fs12m algcenter">12/06/2022</h1>
      </div>
      <div className="t-container text-1-container fs32 fs16m">
        <div className="left">
          <p>
            Are you curious about UI and UX design and keen to learn more?
            Whether or not UI and UX design are a mystery to you, people have
            different perspectives whether there are differences or if they are
            both parts of one another. Here’s a look into the essence of both
            disciplines.
          </p>
          <p>
            User interface (UI) is the series of screens, pages, and visual
            elements—like buttons and icons—that enable a person to interact
            with a product or service. On the other hand, User experience (UX)
            is the process of creating products and internal experiences that a
            person has as they interact with every aspect of a company’s
            products and services as efficiently and effectively as possible.
          </p>
          <p>
            In other words, UX design covers everything regarding the user
            experience, from basic functionality to interactions. While UI
            design concerns only the interface, UX design regards the entire
            experience that users take away from the product. That means that
            while the UI is a crucial aspect of UX, it is only a limited part of
            the whole. User experience evolved as a result of the improvements
            to UI. Once there was something for users to interact with, their
            experience, whether positive, negative, or neutral, changed how
            users felt about those interactions.
          </p>
        </div>
        <div className="right">
          <img src={uiux1} alt="" />
        </div>
      </div>
      <div className="t-container text-2-container fs32 fs16m">
        <div className="left">
          <h2>What is the difference between UI and UX?</h2>
          <div>
            <p>
              Not surprisingly, different people have different takes on this
              topic. Here’s what some smart and talented folks from the tech
              industry had to say about the difference between UI and UX:
            </p>
            <p className="fw700">
              1. UX is focused on the user’s journey to solve a problem, UI is
              focused on how a product’s surfaces look and function. - Ken
              Norton – Partner at Google Ventures, former Product Manager at
              Google
            </p>
          </div>
          <p className="fw700">
            2. A UX designer is concerned with the conceptual aspects of the
            design process, leaving the UI designer to focus on the more
            tangible elements - Andy Budd – Co-founder of Clearleft, Founder of
            UX London
          </p>
          <p className="fw700">
            3. UI is focused on the product, a series of snapshots in time. UX
            focuses on the user and their journey through the product - Scott
            Jenson – former Product Strategist at Google
          </p>
        </div>
        <div className="right">
          <img src={uiux2} alt="" />
        </div>
      </div>
      <div className="t-container text-3-container fs32 fs16m">
        <div className="left">
          <img src={uiux3} alt="" />
        </div>
        <div className="right">
          While interface design is all about what happens at the surface, UX
          design covers the deep function of the entire product. UX designers
          must consider not only the main goal that users will have when using
          the product but also how they can ensure that can happen smoothly and
          easily.
        </div>
      </div>
      <div className="t-container text-4-container fs32 fs16m">
        <h2>
          UX focuses on the big and conceptual, while UI focuses on the tangible
        </h2>
        <p>
          UX tends to deal with the bigger issues of the entire project. UX
          designers will worry about things like the information architecture,
          strategy, and main goals of the product. All of these represent
          high-impact areas of the product, dealing with issues like the
          competition and secondary features that could be added. These can
          drastically change the product, shaping the very nature of the
          product. UX designers will deal with questions like:
        </p>
        <div>
          <p className="fw700">
            • What circumstances lead to users needing this product?
          </p>
          <p className="fw700">
            • Why would they choose this over a competitor?
          </p>
          <p className="fw700">
            • How can we make the product experience more efficient?
          </p>
          <p className="fw700">• Are all the product requirements aligned?</p>
        </div>
        <p>
          UX design can indeed be considered a more complex road when compared
          to UI design. However, that is not to say that UI design is easy or
          simple by any means. In exchange, UI designers tend to deal with more
          specific and concrete aspects of the product. Instead of worrying
          about why users would even want the product, UI designers will focus
          on creating a layout that potentializes the product’s primary feature.
          They’ll worry about creating a navigation system that reflects and
          respects the information architecture. They’ll compare several element
          combinations until the best one can be selected. It’s immediate and
          specific, creating things that can be put to the literal test very
          quickly.
        </p>
        <h2>Our idea of UI design is changing rapidly</h2>
        <div>
          <p>
            UX design has always dealt with beyond the simple digital
            screen-focused products. It’s no secret that user experiences can be
            had with all sorts of tangible, real-world things like coffee makers
            and microwaves or doorknobs. UX is the type of broad concept that
            touches pretty much everything in our lives.
          </p>
          <p>
            UI design, on the other hand, has had its very nature defined and
            restricted to digital products. It can be easy to write UI design
            off as something narrow and specific that has no place in the real
            world. That, however, may not be an entirely correct way to define
            UI design – at least, it won’t be short. Key examples of new types
            of UI design that push boundaries and our very understanding of
            interfaces include Voice Interface Design and virtual reality.
          </p>
        </div>
      </div>
    </StyledUU>
  );
};
const StyledUU = styled.div`
  padding: 8vw;
  display: flex;
  flex-direction: column;
  gap: 3vw;
  @media (max-width: 768px) {
    gap: 6vw;
  }
  .t-container {
    display: flex;
    gap: 3vw;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &.text-1-container {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 3vw;
      }
      img {
        width: 25vw;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    &.text-2-container {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      img {
        width: 25vw;
        height: 25vw;
        object-fit: cover;
        object-position: 60% 10%;
        margin-top: 7vw;
        @media (max-width: 768px) {
          /* display: none; */
          width: 100%;
          height: auto;
        }
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 3vw;
      }
    }
    &.text-3-container {
      .left {
        img {
          width: 25vw;
          height: 25vw;
          object-fit: cover;
          @media (max-width: 768px) {
            /* display: none; */
            width: 100%;
            height: auto;
          }
        }
      }
    }
    &.text-4-container {
      flex-direction: column;
    }
  }
`;

export default UiUx;
