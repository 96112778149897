import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import logo from "../../images/lightlogo.png";
import { navLinks } from "./Nav";
import fb from "../../images/sm-icons/fb.png";
import ig from "../../images/sm-icons/ig.png";
import li from "../../images/sm-icons/li.png";
const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer-content">
        <img src={logo} alt="logo" className="footer-logo" />
        <div className="col1">
          <div className="quick-links">
            <h1 className="qlinks-heading fs16 fs16m">Quick Links</h1>
            <div className="qlinks-grid">
              {navLinks.map((link) => (
                <a
                  className="fs16 fs14m"
                  key={link.name}
                  href={link.path || "/"}
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="col2">
          <h1 className="contacts-heading fs16 fs16m">Contact Us</h1>
          <div className="contacts fs16 fs14m">
            <p>
              Number: <a href="tel:+01114824935">01114824935</a>
            </p>
            <p>
              Email: <a href="mailto:contact-us@spree.com">contact-us@spree.com</a>
            </p>
            <p>Address: New Cairo</p>
          </div>
        </div>
        <div className="col3">
          <div className="sm-links">
            <a
              target="_blank"
              href="https://www.facebook.com/Spree-Solutions-103314712181043"
            >
              <img src={fb} alt="fb" />
            </a>
            <a target="_blank" href="https://www.instagram.com/spreesolutions">
              <img src={ig} alt="ig" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/spreesolutionsco"
            >
              <img src={li} alt="li" />
            </a>
          </div>
          <h1 className="fs16 sm-title">Follow us on our socials</h1>
          <p className="fs16 sm-caption">
            Be part of the Spree community and never miss an update
          </p>
        </div>
      </div>
      <div className="policy-and-cr">
        <div className="terms-conditions fs12">
          <a href="/">Privacy Policy</a>
          <p>|</p>
          <a href="/">Terms and Conditions</a>
        </div>
        <p className="fs12 cr">
          Copyright 2022 Spree Solutions | All Rights Reserved
        </p>
      </div>
    </StyledFooter>
  );
};
const StyledFooter = styled.div`
  background: ${colors.mainBlue};
  padding: 3vw 4.7vw 1vw;
  height: 17vw;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    height: 135.5vw;
    padding: 0 6.4vw 6.9vw;
  }
  .footer-content {
    align-items: flex-start;
    .footer-logo {
      width: 12vw;
      object-fit: contain;
      height: auto;
      @media (max-width: 768px) {
        height: 17.9vw;
        width: 17.3vw;
      }
    }
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }
    .col1 {
      display: flex;
      flex-direction: column;
      .qlinks-heading {
        color: white;
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin-bottom: 4.3vw;
        }
      }
      .quick-links {
        @media (max-width: 768px) {
          margin-top: 5vw;
        }
        .qlinks-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.8vw 0.8vw;
          width: 17.6vw;
          @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 2.9vw 5.6vw;
            width: auto;
          }
          a {
            color: white;
            text-decoration: none;
          }
        }
      }
    }
    .col2 {
      /* width: 33.125vw; */
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-top: 8vw;
      }
      h1,
      p {
        color: white;
      }
      .contacts-heading {
        margin-bottom: 1vw;
      }
      .contacts {
        @media (max-width: 768px) {
          margin-top: 3.44vw;
        }
        p {
          a {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
    .col3 {
      h1,
      p {
        color: white;
      }
      @media (max-width: 768px) {
        margin-top: 8vw;
      }
      .sm-links {
        /* height: 5.84vw; */
        margin-bottom: 1.2vw;
        /* background: white; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        img {
          width: 3vw;
          height: 3vw;
          border-radius: 50%;
          @media (max-width: 768px) {
            width: 9vw;
            height: 9vw;
          }
        }
        @media (max-width: 768px) {
          height: 12.2vw;
          width: 69.152vw;
        }
      }
      .sm-title {
        @media (max-width: 768px) {
          display: none;
        }
      }
      .sm-caption {
        margin-top: 0.5vw;
        width: 19.43vw;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  .policy-and-cr {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 0;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 2vw;
    }
    .terms-conditions {
      display: flex;
      gap: 0.5vw;
      margin-top: auto;
      @media (max-width: 768px) {
        gap: 2vw;
      }
      a,
      p {
        color: white;
        text-decoration: none;
        @media (max-width: 768px) {
          font-size: 2.7vw;
        }
      }
    }
    .cr {
      margin-top: auto;
      color: white;
      @media (max-width: 768px) {
        font-size: 2.7vw;
      }
    }
  }
`;
export default Footer;
