import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import { ReactComponent as Left } from "../../images/left.svg";
import { ReactComponent as Right } from "../../images/right.svg";
import Button from "../assets/Button";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const ServiceSummary = ({
  services,
  service,
  selectedService,
  setSelectedService,
}) => {
  const previous = () => {
    if (selectedService > 0) {
      setSelectedService(selectedService - 1);
    } else {
      setSelectedService(services.length - 1);
    }
  };
  const next = () => {
    if (selectedService < services.length - 1) {
      setSelectedService(selectedService + 1);
    } else {
      setSelectedService(0);
    }
  };
  return (
    <AnimatePresence exitBeforeEnter>
      <StyledSection
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        key={selectedService}
      >
        <div className="col col1">
          {service.illustration && <img src={service.illustration} alt="" />}
        </div>
        <div className="col col2">
          <div className="service-summary-navigation">
            <button onClick={previous}>
              <Left />
            </button>
            <button onClick={next}>
              <Right />
            </button>
          </div>
          <h1 className="fs47 fs24m algcenter-mo">{service.name}</h1>
          <div className="service-summary-text">
            {service.text?.map((p) => (
              <p key={Math.random(2342342)} className="fs22 fs14m">
                {p}
              </p>
            ))}
            {service.lists && (
              <div className="service-lists">
                {service.lists.map((list) => (
                  <div className="service-list" key={list.title}>
                    <p className="fs22 fs14m">{list.title}</p>
                    <ul className="fs22">
                      {list.content?.map((point) => (
                        <li className="fs14m" key={Math.random(12)}>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
            {service.endText && <p className="fs22 fs14m">{service.endText}</p>}
          </div>
          <Button className="get-quo">
            <Link className="fs20 fs16m fw700" to="/get-quotation">
              Request a free quotation
            </Link>
          </Button>
        </div>
      </StyledSection>
    </AnimatePresence>
  );
};

const StyledSection = styled(motion.div)`
  padding: 0 6.43vw;
  background: ${colors.fadedBlue};
  min-height: 41.17vw;
  display: flex;
  /* align-items: center; */
  gap: 4.2vw;
  @media (max-width: 768px) {
    padding: 12.32vw 6.4vw 15.7vw;
    flex-direction: column;
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .col1 {
    padding: 3.6vw 0;
    flex: 1;
    justify-content: center;
    img {
      width: 38.2vw;
      height: auto;
      @media (max-width: 768px) {
        max-width: unset;
        /* max-height: unset; */
        width: 100%;
      }
    }
  }
  .col2 {
    padding-top: 3.6vw;
    .service-summary-navigation {
      @media (max-width: 768px) {
        display: none;
      }
      display: flex;
      margin-bottom: 1.8vw;
      justify-content: flex-end;
      svg {
        /* cursor: pointer; */
        width: 3.2vw;
        height: 2.068vw;
      }
      button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
      }
    }
    .service-summary-text {
      width: 40.6vw;
      margin-top: 0.8vw;
      margin-bottom: 2vw;
      display: flex;
      flex-direction: column;
      gap: 1.8vw;
      line-height: 1.7vw;
      /* margin-bottom: 0.8vw; */
      @media (max-width: 768px) {
        width: auto;
        margin-top: 4.6vw;
        line-height: 5.6vw;
      }
      ul {
        li {
          margin-top: 1vw;
        }
      }
    }
    .get-quo {
      margin-top: auto;
      margin-bottom: 3.75vw;
      width: fit-content;
      @media (max-width: 768px) {
        margin-top: 14vw;
        width: 100%;
      }
    }
  }
`;
export default ServiceSummary;
