import React, { useState } from "react";
import ServicesIntro from "../components/services/ServicesIntro";
import ServiceSummary from "../components/services/ServiceSummary";
import ServicesWhyUs, {
  WhyUsSubSection,
} from "../components/services/ServicesWhyUs";
import DevCycles from "../components/services/DevCycles";
import { servicesDetailed } from "../util/data";

const Services = () => {
  const [selectedService, setSelectedService] = useState(0);
  return (
    <div>
      <ServicesIntro
        services={servicesDetailed}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <ServiceSummary
        services={servicesDetailed}
        service={servicesDetailed[selectedService]}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <DevCycles />
      <ServicesWhyUs />
      <WhyUsSubSection />
    </div>
  );
};

export default Services;
