import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../util/colors";

const NewsLetter = () => {
  const navigate = useNavigate();
  return (
    <StyledSection>
      <h1 className="fs57 fs32m">Subscribe to our Newsletter</h1>
      <p className="fs30 fs14m">
        Stay updated with latest technology trends and topics with us.
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/newsletter-request-success");
        }}
      >
        <div className="input-div">
          <input required type="text" placeholder="email@company.com" />
          <button type="submit" className="fs30 fs16m">
            Subscribe
          </button>
        </div>
      </form>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  padding: 3vw 8.7vw 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
  h1 {
    @media (max-width: 768px) {
      margin-bottom: 4vw;
    }
  }
  form {
    margin-top: 2.5vw;
    @media (max-width: 768px) {
      margin-top: 9.1vw;
    }
    .input-div {
      position: relative;
      width: 47.3vw;
      height: 5.2vw;
      @media (max-width: 768px) {
        width: 100%;
        height: 12.8vw;
      }
      input {
        &::placeholder {
          font-size: 1.6vw;
          color: #d9d8db;
          @media (max-width: 768px) {
            font-size: 4.3vw;
          }
        }
        padding-left: 4.42vw;
        padding-right: 13vw;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        box-shadow: 0 0.26vw 1.04vw rgba(0, 0, 0, 0.16);
        /* @media (max-width: 768px) {
          padding-left: 11.73vw;
          box-shadow: 0 1.3vw 5.3vw rgba(0, 0, 0, 0.16);
        } */
      }
      button {
        position: absolute;
        right: 2.8vw;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        outline: none;
        border: none;
        color: #932fff;
        cursor: pointer;
      }
    }
  }
`;
export default NewsLetter;
