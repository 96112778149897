import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as SeeMore } from "../../images/blogseemore.svg";
import colors from "../../util/colors";
import ScrollAnimate from "./animations/ScrollAnimate";
import blogbg from "../../images/blogbg.jpg";

const BlogPostCard = ({
  title,
  text,
  project,
  date,
  clientName,
  link,
  website,
  logo,
  screenshot,
}) => {
  console.log(link);
  return (
    <ScrollAnimate threshold={0.3}>
      <StyledCard className="desktop-only" project={project}>
        <img
          src={screenshot || blogbg}
          alt="blog"
          className="blog-card-image"
        />
        <div className="blog-card-text">
          {project ? (
            <div className="project-text-header">
              <div className="row-header">
                {/* <div className="project-client-img"> */}
                <img className="project-client-img" src={logo} alt="" />
                {/* </div> */}
                <div className="row-header-text">
                  <h1 className="fs27">{clientName}</h1>
                  <p className="fs23">{date}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="blog-text-header">
              <h1>{title}</h1>
              <Link to={link}>
                <SeeMore />
              </Link>
            </div>
          )}
          <p className="main-text">{text}</p>
          {project && (
            <div className="project-links">
              <Link to={link} className="project-link">
                View Project
                <SeeMore />
              </Link>
              <a target="_blank" href={website} className="project-link">
                Visit website
                <SeeMore />
              </a>
            </div>
          )}
        </div>
      </StyledCard>
      <StyledMobileCard className="mobile-only">
        <img
          src={screenshot || blogbg}
          alt="blog"
          className="blog-card-image"
        />
        {project && (
          <div className="project-text-header">
            <div className="row-header">
              <img className="project-client-img" src={logo} />
              <div className="row-header-text">
                <h1 className="fs27 fs14m">{clientName}</h1>
                <p className="fs23 fs14m">{date}</p>
              </div>
            </div>
          </div>
        )}
        <div className="blog-text-header">
          <h1 className="fs18m">{title}</h1>
          <div className="text-container">
            <p className="fs14m">{text}</p>
            <Link className="go-to-project-mobile" to={link}>
              {">"}
            </Link>
          </div>
        </div>
      </StyledMobileCard>
    </ScrollAnimate>
  );
};
const StyledCard = styled.div`
  width: 36.4vw;
  height: 32.3vw;
  position: relative;
  display: flex;
  align-items: center;
  .blog-card-image {
    /* z-index: -1; */
    right: 0;
    position: absolute;
    width: 23.6vw;
    height: 32.31vw;
    object-fit: cover;
    border-radius: 0.57vw;
    box-shadow: 0.52vw 0.68vw 0.833vw rgba(0, 0, 0, 0.16);
  }
  .blog-card-text {
    z-index: 2;
    background: white;
    width: 19.5vw;
    height: 23.5vw;
    border-radius: 0.57vw;
    box-shadow: 0.52vw 0.68vw 0.833vw rgba(0, 0, 0, 0.16);
    padding: 2.24vw 1.4vw 0.9vw 2.1vw;
    display: flex;
    flex-direction: column;
    .blog-text-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 1.7vw;
        width: 11.8vw;
      }
      svg {
        width: 3.85vw;
        height: 3.85vw;
      }
    }
    .project-text-header {
      .row-header {
        display: flex;
        align-items: center;
        gap: 1vw;
        .row-header-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .project-client-img {
          width: 4.7vw;
          height: 4.7vw;
          border-radius: 50%;
          /* background-color: grey; */
        }
      }
    }
    .main-text {
      font-size: 1.2vw;
      flex: ${(props) => (props.project ? "" : "1")};
      height: ${(props) => (props.project ? "11.2vw" : "")};
      width: 14vw;
      overflow: hidden;
      margin-top: 1.36vw;
    }
    .project-links {
      display: flex;
      justify-content: space-between;
      margin-top: 1.25vw;
      .project-link {
        text-decoration: none;
        font-size: 0.8vw;
        color: ${colors.mainYellow};
        display: flex;
        gap: 0.22vw;
        align-items: center;
        svg {
          width: 1.44vw;
          height: auto;
        }
      }
    }
  }
`;

const StyledMobileCard = styled.div`
  width: 87.2vw;
  min-height: 74.8vw;
  border-radius: 2.9vw;
  box-shadow: 2.7vw 3.5vw 4.3vw rgba(0, 0, 0, 0.16);
  padding: 4.3vw;
  img {
    width: 100%;
    height: 39.4vw;
    object-fit: cover;
    border-radius: 2.9vw;
  }
  .text-container {
    display: flex;
    align-items: center;
    .go-to-project-mobile {
      border: none;
      outline: none;
      font-size: 8vw;
      background: none;
      font-weight: 700;
      color: ${colors.mainYellow};
      cursor: pointer;
    }
  }
  .project-text-header {
    margin-bottom: 3vw;
    margin-top: 3vw;
    .row-header {
      display: flex;
      align-items: center;
      gap: 3vw;
      .row-header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .project-client-img {
        width: 9vw;
        height: 9vw;
        border-radius: 50%;
      }
    }
  }
`;

export default BlogPostCard;
