import i1 from "../images/services/s1.svg";
import i2 from "../images/services/s2.svg";
import i3 from "../images/services/s3.svg";
import i4 from "../images/services/s4.svg";
import { ReactComponent as WebDev } from "../images/services/webdev.svg";
import { ReactComponent as UiUx } from "../images/services/uiux.svg";
import { ReactComponent as Seo } from "../images/services/seo.svg";
import { ReactComponent as AppDev } from "../images/services/mobileappdev.svg";
import { ReactComponent as Maintenance } from "../images/services/maintenance.svg";
import { ReactComponent as Testing } from "../images/services/testing.svg";
import { ReactComponent as Integration } from "../images/services/integration.svg";
import { ReactComponent as Hosting } from "../images/services/hosting.svg";
import webdevdet from "../images/services/services-details/webdevdet.svg";
import appdevdet from "../images/services/services-details/appdevdet.svg";
import maintenancedet from "../images/services/services-details/maintenancedet.svg";
import seodet from "../images/services/services-details/seodet.svg";
import uiuxdet from "../images/services/services-details/uiuxdet.svg";
import testingdet from "../images/services/testing.svg";
import integrationdet from "../images/services/services-details/integrationdet.svg";
import hostingdet from "../images/services/services-details/hostingdet.svg";
export const services = [
  {
    name: "Customize your design",
    text: "The choice to tailor the design of your app and give your customers a personalized experience",
    image: i1,
  },
  {
    name: "Tested Solutions",
    text: "You get a bug-free app solution and validated user interface (UI) to meet modern-day challenges.",
    image: i2,
  },
  {
    name: "Your App, Your Rights",
    text: "Own the rights to the source code of your mobile app & get all property rights.",
    image: i3,
  },
  {
    name: "Add More Value With Technology",
    text: "We provide you with digital solutions which are adaptable to changing customer demand & behavior and the flexibility to use third-party integrations to improve your business processes.",
    image: i4,
  },
];

export const servicesDetailed = [
  {
    name: "Web Development",
    image: WebDev,
    illustration: webdevdet,
    text: [
      "Spree Solutions provides Web development that can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.",
      "Expand your reach to your ideal customer and get real-time analytics and many more by your website. With your business objectives in mind, we build every part of your website aligned with them. And relying on the latest technologies, we deliver the most tailored websites to your business so our websites always meet and exceed expectations.",
      "You’ll be working with our in-house developers throughout the project. The team follows an organized structured development cycle with all projects. Our developers are supported by designers, technicians, and a test team. Providing you with everything to deliver a top-notch product.",
    ],
  },
  {
    name: "Mobile App Development",
    image: AppDev,
    illustration: appdevdet,
    text: [
      "Spree provides Mobile app development as an act or process by which a mobile app is developed for mobile devices.",
      "At its core, mobile application development depends on the ease of access anytime and anywhere. The excellent interface design and ease of use we follow when developing your app will help your business stand out among other mobile apps. Whether you are a startup or a well-known brand, Spree Solutions will help you put your business in your customers’ pockets through a dynamic, user-friendly, and multi-functional mobile app.",
      "A great app starts with the right mobile app development technology. Spree Solutions has a team of highly-skilled, professional, and experienced mobile application developers that will take your mobile app from vision to reality. We provide the user experience and functionality of native mobile apps with multi-platform compatibility to support both Android and IOS platforms.",
      "You’ll be working with our in-house developers throughout the project. The team follows an …… with all projects. Our developers are supported by designers, technicians, and a test team. This means that we’re able to provide you with everything you require to maximize the quality of the product delivery.",
    ],
  },
  {
    name: "Software Maintenance",
    image: Maintenance,
    illustration: maintenancedet,
    text: [
      "Spree Solutions provides software maintenance as customers’ expectations from online businesses are constantly evolving, and so should your business. Regularly updating your website and fixing its issues maintains it fresh, responsive, well accessible, and highly encouraging for customers to take quick action.",
      "The best website maintenance is that gives dedicated care to your website regardless of its size, makes security backups, and many more. All are included in a monthly tracking report to stay on top of any possible issues.",
    ],
  },
  {
    name: "SEO",
    image: Seo,
    illustration: seodet,
    text: [
      "Driving visitors to your website is normal but attracting your ideal client is our thing! At first, we thoroughly analyze your website performance, set our winning strategy for your business, and then dedicatedly work to it. Providing you with clear KPIs, you will be able to envision and track your website until it is on the first page driving impressive traffic over time.",
      "We collaborate with the marketing team to gather metadata, keywords, and tags of our clients. We enhance your brand image with data related to your keywords that should be embedded in the system and be added in a way that search engines get your website and brand ranking higher on google searches.",
    ],
  },
  {
    name: "UI/UX WEB AND APP DESIGN",
    image: UiUx,
    illustration: uiuxdet,
    text: [
      "A website should be engaging, easy to navigate, and focused on your target audience. The user journey dictates whether you convert a prospect into a profitable customer. Each decision you make about the design will affect how a user interacts with the page. You’ve got to include the right information so that users can make informed decisions and your calls to action should drive them toward a key goal. We’ll create a website that puts your users first. We’ll guide you on the best features and create user-focused designs with smooth navigation to make sure your website converts. Whether you want it to convert leads, make sales or drive traffic, your website will be designed to achieve your goals. To build your presence on the Internet, we offer a cost-effective solution based on powerful and scalable tools for the development of a website.",
    ],
  },
  // {
  //   name: "Software Integrations",
  //   image: Integration,
  //   illustration: integrationdet,
  //   text: [
  //     "Software integration is the process of bringing together various types of software sub-systems so that they create a unified single system. Linking different systems, such as various databases and file-based systems. Joining various stand-alone systems makes replicating processes easier and gaining uniform results.",
  //   ],
  // },
  {
    name: "Software Testing",
    image: Testing,
    illustration: testingdet,
    text: [
      "Software testing is the process of evaluating and verifying that a software product or application does what it is supposed to do. The benefits of testing include preventing bugs, reducing development costs, and improving performance.",
    ],
    lists: [
      {
        title:
          "There are different types of software tests, each with specific objectives and strategies:",
        content: [
          "Acceptance testing: Verifying whether the whole system works as intended.",
          "Integration testing: Ensuring that software components or functions operate together.",
          "Unit testing: Validating that each software unit performs as expected. A unit is the smallest testable component of an application.",
          "Functional testing: Checking functions by emulating business scenarios, based on functional requirements. Black-box testing is a common way to verify functions.",
          "Performance testing: Testing how the software performs under different workloads. Load testing, for example, is used to evaluate performance under real-life load conditions.",
          "Regression testing: Checking whether new features break or degrade functionality. Sanity testing can be used to verify menus, functions, and commands at the surface level when there is no time for a full regression test.",
          "Stress testing: Testing how much strain the system can take before it fails. Considered to be a type of non-functional testing.",
          "Usability testing: Validating how well a customer can use a system or web application to complete a task.",
        ],
      },
    ],
    endText:
      "In each case, validating base requirements is a critical assessment. Just as important as exploratory testing helps a tester or testing team uncover hard-to-predict scenarios and situations that can lead to software errors. Even a simple application can be subject to a large number and variety of tests. A test management plan helps to prioritize which types of testing provide the most value-given available time and resources. Testing effectiveness is optimized by running the fewest number of tests to find the largest number of defects.",
  },
  {
    name: "Cloud Hosting and Management",
    image: Hosting,
    illustration: hostingdet,
    text: [
      "The digital world is full of online opportunities for your website, catch it with the world’s most reliable cloud hosting that never fails; the failure rate is less than 0.01 emoji or something to host top-performing websites in Egypt and the MENA region.",
      "Hosting your Web, mobile app or your system is as important as its development. We have an expert team with a strong background in cloud hosting services with the top companies such as AWS - Azure - Google cloud. Our team deeply takes care of everything starting from the Software cloud architecture or resource monitoring to the cost monitoring. In addition, our team assures your project scalability by using the latest load balancing frameworks. to adapt to everything event or traffic driven phase. We look clearly at the resources used vs. the scalability goal.",
    ],
  },
];
