import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CategoryCard = ({ text, image, link, ...props }) => {
  return (
    <StyledCard image={image} {...props}>
      <p className="fs27 fs16m fw700 category-text">{text}</p>
      <Link to={link || "/"} className="fs20 fs14m">
        More {">"}
      </Link>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  min-width: 15.7vw;
  min-height: 14.37vw;
  background-image: url(${(props) => props.image});
  background-size: 100%;
  overflow: hidden;
  border-radius: 0.57vw;
  box-shadow: 0.52vw 0.68vw 1.35vw rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.8vw 0;
  .category-text {
    color: white;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: white;
    align-self: flex-end;
    padding: 0 1.84vw;
  }
`;
export default CategoryCard;
