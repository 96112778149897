import React from "react";
import CareersHeader, {
  CareersFooter,
} from "../components/careers/CareersHeader";
import Openings from "../components/careers/Openings";

const Careers = () => {
  return (
    <div>
      <CareersHeader />
      <Openings />
      <CareersFooter />
    </div>
  );
};

export default Careers;
