import React from "react";
import styled from "styled-components";
import Line from "../assets/Line";
import fnb from "../../images/fnb.jpg";
import CategoryCard from "../assets/CategoryCard";
const SpreeSolutions = () => {
  const categories = [
    { name: "Food & beverage", image: fnb, link: "/asd" },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
    { name: "Food & beverage", image: fnb },
  ];
  return (
    <StyledSection>
      <div className="ss-container">
        <Line />
        <h1 className="fs67 fs32m fw700">Spree Solutions</h1>
        <p className="fs30 fs14m ss-parag">
          A paragraph about Spre lorem Epsom skjhbsaicbasjhcbjakschb j ouch such
          kjxcb cjk iuds jkdh kjh dochisdh sjh vkjhvhdbv ksjhhs hs shd. Ganbduwb
          dk ainwajbabdoba iubdiw ain sh aknduw. Skab sisbakj os nx
          ksnahosnakkxj A paragraph about Spre lorem Epsom
          skjhbsaicbasjhcbjakschb j ouch such kjxcb cjk iuds jkdh kjh dochisdh
          sjh vkjhvhdbv ksjhhs hs shd. Ganbduwb dk ainwajbabdoba iubdiw ain sh
          aknduw.
        </p>
      </div>
      <div className="categories desktop-only">
        {categories.map((c) => (
          <CategoryCard
            key={Math.random(2131231)}
            text={c.name}
            image={c.image}
            link={c.link}
          />
        ))}
      </div>
      <div className="categories-mobile mobile-only">
        {categories.map((c) => (
          <CategoryCard
            key={Math.random(2131231)}
            text={c.name}
            image={c.image}
            link={c.link}
            className="mobile-card"
          />
        ))}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  /* height: 29.88vw; */
  min-height: 29.88vw;
  padding: 5.6vw 0 0 0vw;
  position: relative;
  @media (max-width: 768px) {
    min-height: auto;
    padding: 13.3vw 0 6.5vw 0;
  }
  .ss-container {
    padding-left: 8.6vw;
    @media (max-width: 768px) {
      padding: 0 6.4vw;
    }
  }
  h1 {
    margin-top: 1.125vw;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  .ss-parag {
    width: 76.8vw;
    margin-top: 1.67vw;
    @media (max-width: 768px) {
      width: auto;
      margin-top: 4.3vw;
    }
  }
  .categories {
    display: flex;
    gap: 1.9vw;
    overflow: hidden;
    margin-top: 4.375vw;
  }
  @media (max-width: 768px) {
    .categories-mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 16.8vw 6.4vw 0;
      gap: 6.4vw;
      .mobile-card {
        padding: 3.4vw 4vw;
        width: 40vw;
        height: 40vw;
        border-radius: 2.9vw;
        p {
          width: 22vw;
          text-align: left;
        }
      }
    }
  }
`;
export default SpreeSolutions;
