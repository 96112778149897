import React from "react";
import styled from "styled-components";
import ServicesListCard from "../assets/ServicesListCard";

const ServicesIntro = ({ services, selectedService, setSelectedService }) => {
  return (
    <StyledSection className="services-page-intro">
      <h1 className="fs57 fs32m fw700">Our Services</h1>
      <p className="fs27 fs14m">THE MOST COMPREHENSIVE SOFTWARE HOUSE</p>
      <p className="fs27 fs14m">TAKE YOUR BUSINESS TO THE NEXT LEVEL</p>
      <div className="services-list">
        {services.map((service, index) => (
          <ServicesListCard
            onClick={() => setSelectedService(index)}
            selected={selectedService === index}
            key={service.name}
            name={service.name}
            image={service.image}
          />
        ))}
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  padding: 2.13vw 0 5.13vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 13.9vw 6.4vw 8.24vw;
  }
  .services-list {
    margin-top: 1.3vw;
    display: flex;
    gap: 0.69vw;
    @media (max-width: 768px) {
      margin-top: 9.4vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 3.23vw;
      grid-column-gap: 3.9vw;
    }
  }
`;
export default ServicesIntro;
