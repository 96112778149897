import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import ReviewCard from "../assets/ReviewCard";
import { ReactComponent as Right } from "../../images/right.svg";
import { ReactComponent as Left } from "../../images/left.svg";

const HappyCustomers = () => {
  return (
    <StyledSection className="happy-customers-section">
      <h1 className="happy-customers-heading fs57 fs32m fw700">
        Meet Our Happy Customers
      </h1>
      <div className="happy-customers-container">
        <Left />
        <div className="reviews-carousel">
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
        </div>
        <Right />
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  .happy-customers-heading {
    text-align: center;
    margin-bottom: 1.8vw;
    @media (max-width: 768px) {
      text-align: left;
      margin-bottom: 4.8vw;
      padding-left: 6.4vw;
    }
  }
  .happy-customers-container {
    height: 30.7vw;
    background: ${colors.mainBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4.2vw;
    @media (max-width: 768px) {
      height: auto;
      display: block;
      padding: 8.19vw 4.16vw 10.75vw;
    }
    svg {
      width: 4.2vw;
      height: 2.7vw;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .reviews-carousel {
      width: 70.4vw;
      /* margin: auto; */
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        width: auto;
        flex-direction: column;
        gap: 9.3vw;
      }
    }
  }
`;
export default HappyCustomers;
