import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import { ReactComponent as Top } from "../../images/icons/toparrow.svg";
const ScrollBtn = () => {
  const [windowScroll, setWindowScroll] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setWindowScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setWindowScroll(window.scrollY);
      });
    };
  }, []);
  return (
    <StyledBtn
      className={`${windowScroll < 300 ? "hidden-scroll-btn" : ""}`}
      onClick={() => {
        window.scroll(0, 0);
      }}
    >
      <Top />
    </StyledBtn>
  );
};
const StyledBtn = styled.button`
  z-index: 2000000;
  &.hidden-scroll-btn {
    visibility: hidden;
  }
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  outline: none;
  border: none;
  width: 3vw;
  height: 3vw;
  background: ${colors.mainBlue};
  color: white;
  border-radius: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0.2vw solid white;
  @media (max-width: 768px) {
    width: 8vw;
    height: 8vw;
    bottom: 3vw;
    right: 3vw;
    border-radius: 1.5vw;
    outline: 0.5vw solid white;
  }
  cursor: pointer;
  svg {
    width: 70%;
    height: 70%;
    object-fit: contain;
    fill: white;
  }
`;
export default ScrollBtn;
