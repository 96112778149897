import React from "react";
import styled from "styled-components";
import blogbg from "../../images/blogbg.jpg";
import BlogPostCard from "../assets/BlogPostCard";
import Button from "../assets/Button";
import { ReactComponent as Search } from "../../images/icons/search.svg";
import equityLogo from "../../images/projects/equitylogo.png";
import naLogo from "../../images/projects/nalogo.png";
import equityss from "../../images/projects/equityscreensh.jpg";
import nass from "../../images/projects/nascreensh.jpg";

export const projects = [
  {
    title: "Equity Property Management",
    text: "Equity is a property management company that manages everything related to a household whether it’s cleaning and maintenance or even selling or renting properties including all legal regulations and contracts. Equity Egypt is also a subsidiary of the leading financial firm, Panther Associates. They aim to create value for their clients by providing financial solutions.",
    image: blogbg,
    clientName: "Equity Egypt",
    date: "17/08/2021",
    link: "/projects/equity",
    website: "http://www.equityegypt.com",
    logo: equityLogo,
    screenshot: equityss,
  },
  {
    title: "The Nile Anchor",
    text: "Nile anchor is a luxurious, hospitable onboard service cruise boat. They aim to provide a high-end value by developing exclusive offers and premium packages that cater to the needs to develop memorable, breathtaking moments on Egypt’s Nile River. Nile Anchor provides a service that takes you on a prehistoric journey vs. today’s modern age; a journey that takes you for a walk on Egypt’s ancient river accompanied by an expressive view of today’s modern world; Cairo and Giza’s districts.",
    image: blogbg,
    clientName: "The Nile Anchor",
    date: "02/07/2021",
    link: "/projects/thenileanchor",
    website: "http://www.thenileanchor.com",
    logo: naLogo,
    screenshot: nass,
  },
];
const ProjectsContainer = ({ selectedCategory }) => {
  return (
    <StyledSection>
      <h1 className="fs60 fs16m algcenter-do">{selectedCategory.name}</h1>
      <div className="projects-filters">
        {/* <select className="fs20 fs14m" defaultValue={""}>
          <option value="" disabled>
            Sort by
          </option>
          <option value="#">option</option>
          <option value="#">option</option>
          <option value="#">option</option>
        </select>
        <form>
          <Search />
          <input
            className="fs20 fs14m"
            type="text"
            placeholder="Search our projects.."
          />
        </form> */}
      </div>
      <div className="projects-posts-container-page">
        {projects.map((post) => (
          <BlogPostCard
            key={Math.random(17387232)}
            title={post.title}
            text={post.text}
            clientName={post.clientName}
            date={post.date}
            link={post.link}
            website={post.website}
            logo={post.logo}
            screenshot={post.screenshot}
            project
          />
        ))}
      </div>
      {/* <div className=" fs30 projects-pagination">
        <p>Showing 6 of 36</p>
        <Button>Load more</Button>
      </div> */}
    </StyledSection>
  );
};
const StyledSection = styled.div`
  background: #f2f1f7;
  padding: 3.16vw 0vw 2.7vw 7.6vw;
  @media (max-width: 768px) {
    padding: 11.5vw 6.4vw;
    background-color: white;
  }
  .projects-filters {
    margin-top: 1.3vw;
    display: flex;
    gap: 2.3vw;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
    }
    form {
      position: relative;
      svg {
        position: absolute;
        right: 1.32vw;
        top: 50%;
        transform: translateY(-50%);
        width: 1.6vw;
        height: 1.77vw;
        @media (max-width: 768px) {
          width: 5vw;
          height: 6vw;
          right: 3vw;
        }
      }
    }
    select,
    input {
      display: block;
      padding-left: 1.6vw;
      width: 17.29vw;
      height: 3.02vw;
      border-radius: 0.8vw;
      outline: none;
      border: solid 0.05vw #707070;
      @media (max-width: 768px) {
        width: auto;
        flex: 1;
        height: 11.7vw;
        border-radius: 1.5vw;
      }
      &::placeholder {
        color: #c4c2cb;
      }
    }
  }
  .projects-posts-container-page {
    margin-top: 4.2vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4vw;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 6.9vw;
    }
  }
  .projects-pagination {
    margin: auto;
    width: fit-content;
    text-align: center;
    margin-top: 3.45vw;
  }
`;
export default ProjectsContainer;
