import React, { useState } from "react";
import styled from "styled-components";
import Line from "../components/assets/Line";
import { ReactComponent as I1 } from "../images/about/i1.svg";
import { ReactComponent as I2 } from "../images/about/i2.svg";
import { ReactComponent as I3 } from "../images/about/i3.svg";
import { WhyUsCard } from "../components/home/WhyUs";
import colors from "../util/colors";
import ServicesCarousel from "../components/assets/ServicesCarousel";
import { motion } from "framer-motion";
import { services } from "../util/data";
import ContactUs from "../components/home/ContactUs";
import ServiceCard from "../components/assets/ServiceCard";

const About = () => {
  const whyUs = [
    "We are YOUNG",
    "We are FLEXIBLE",
    "We are RELIABLE",
    "We are AVAILABLE",
  ];

  return (
    <StyledAbout>
      <h1 className="fs47 fs24m algcenter about-h">HOW SPREE CAME TO LIFE</h1>
      <I1 className="illust i1" />
      <Text
        className="our-story"
        width={"57.9vw"}
        title={"Our Story"}
        text={[
          "Spree Solutions is a software development company that develops innovative and creative services that provide total communication and information solutions. Among many benefits, web and mobile design and development, tailor-made applications, ERPs, CRMs, websites, e-commerce solutions, managed hosting, and software maintenance and monitoring are a few that we offer. We are committed to producing high-quality, affordable software using the latest cutting-edge technologies and the best design practices.",
          "Spree started as a fashion platform that combines all fashion brands in one program. We wanted to fulfill the market gaps, and with our vision in technology, we can change the way we interact with things in our messy and busy life. We decided why to create only one platform when we can create many and help others. At first, the idea was the transformation of fashion, then the realization of transforming real apps and shopping cycles with spree and joy. Why settle for one when you can contribute to all? ",
          "In Spree, we are looking to connect all and partner with brands. We provide logistics, technicals, customer service, and marketing altogether. We don't just do what we are asked to do; we create the best solutions for you. We share plans and ideas with our clients along the way as one of our main values is to keep a long-term relationship with our clients. We believe that our client's growth is our growth.",
        ]}
      />
      <I2 className=" illust i2" />
      <Text
        className="our-mission"
        title={"Our Mission"}
        text={[
          "To create a smooth experience for our clients with our top-notch software, mobile applications, websites, hosting, and maintenance services to help uplift their brand’s technology and make them enter the luxuriant digital world in Egypt on flexible and affordable terms.",
        ]}
        width="23.6vw"
      />
      <I3 className="illust i3" />
      <Text
        className="our-vision"
        title={"Our Vision"}
        text={[
          "To be the most comprehensive high-end software company that empowers, grows, and impacts our clients through a trouble-free experience.",
        ]}
        width="21.6vw"
      />
      <div className="why-us-about">
        <div className="container-1">
          <Line />
          <h1 className="fs47 fs18m fw700">WHY SPREE?</h1>
          <p className="fs30 fs12m">
            SPREE IS YOUR ONLY COMPATIBLE SOFTWARE PARTNER
          </p>
          <div className="why-us-about-cards">
            {whyUs.map((whyUs) => (
              <WhyUsCard className="s-why-us-card" text={whyUs} aboutView />
            ))}
          </div>
        </div>
        <div className="why-us-footer-section">
          <p className="footer-intro fs30 fs14m fw700 ">
            Spree has a strong business background team that can integrate all
            aspects to produce high-quality solutions at affordable prices. Not
            only do we have a professional communication process with our
            clients, but we also offer luxurious services and products. Our team
            has unity and alliance that helps us produce the best possible
            solutions.
          </p>
          <ServicesCarousel className="desktop-only" services={services} />
          <div className="mobile-only mobile-services-container">
            {services.map((service) => (
              <ServiceCard
                className="service-card"
                key={service.name}
                service={service}
              />
            ))}
          </div>
        </div>
      </div>
      <ContactUs />
    </StyledAbout>
  );
};

const Text = ({ title, text, width, ...props }) => {
  return (
    <StyledText width={width} {...props}>
      <Line />
      <h1 className="fs38 fs18m">{title}</h1>
      <div className="asset-p-text">
        {text.map((p) => (
          <p className="fs20 fs14m">{p}</p>
        ))}
      </div>
    </StyledText>
  );
};

const StyledAbout = styled.div`
  padding-top: 1.6vw;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-top: 8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-logo {
    width: 16.9vw;
    margin: auto;
    display: block;
    margin-bottom: 1.7vw;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .about-h {
    margin-bottom: 2.5vw;
    @media (max-width: 768px) {
      margin-bottom: 13.9vw;
    }
  }
  .illust {
    position: absolute;
    z-index: -1;
    @media (max-width: 768px) {
      position: static;
    }
    &.i1 {
      width: 31.66vw;
      height: 47.18vw;
      left: -6.7vw;
      top: 3.33vw;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &.i2 {
      width: 60.6vw;
      height: 60vw;
      top: 43.4vw;
      right: -6.3vw;
      @media (max-width: 768px) {
        margin-top: 15.5vw;
        width: 70.6vw;
        height: 70vw;
      }
    }
    &.i3 {
      width: 78.55vw;
      height: 77.7vw;
      top: 57.12vw;
      left: -10.9vw;
      @media (max-width: 768px) {
        margin-top: 13.1vw;
        width: 70.6vw;
        height: 70vw;
      }
    }
  }
  .our-story {
    margin-left: 31vw;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
  .our-mission {
    margin-left: 8.125vw;
    margin-top: 20.37vw;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 8.5vw;
    }
  }
  .our-vision {
    margin-top: 18.4vw;
    margin-left: 53.7vw;
    @media (max-width: 768px) {
      margin-top: 3.7vw;
      margin-left: 0;
    }
  }
  .why-us-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 19vw;
    .container-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 768px) {
        align-items: flex-start;
      }
    }
    @media (max-width: 768px) {
      margin-top: 22.4vw;
      align-items: flex-start;
      width: 100%;
      .container-1 {
        padding: 0 6.4vw;
      }
    }
    h1 {
      margin-top: 0.5vw;
      margin-bottom: 0.9vw;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    .why-us-about-cards {
      margin-top: 4.8vw;
      display: flex;
      gap: 2.2vw;
      margin-top: 4.3vw;
      @media (max-width: 768px) {
        margin-top: 9.92vw;
        gap: 2.4vw;
      }
      .s-why-us-card {
        width: 18.6vw;
        height: 16.6vw;
        @media (max-width: 768px) {
          width: 20vw;
          height: 17.9vw;
          border-radius: 2.9vw;
        }
        p {
          font-size: 1.7vw;
          @media (max-width: 768px) {
            font-size: 3vw;
          }
        }
      }
    }
    .why-us-footer-section {
      margin-top: 5.3vw;
      height: 55.8vw;
      background: ${colors.fadedBlue};
      width: 100%;
      padding-top: 4.375vw;
      @media (max-width: 768px) {
        margin-top: 9.8vw;
        height: auto;
        padding-top: 16.3vw;
        padding-bottom: 22vw;
        .mobile-services-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 9.92vw;
          margin-top: 15vw;
          .service-card {
            min-width: 61.5vw;
            max-width: 61.5vw;
            height: 70.23vw;
            padding: 9vw 3.3vw 6.4vw;
            border-radius: 2.9vw;
            box-shadow: 2.67vw 3.5vw 9.6vw rgba(0, 0, 0, 0.16);
            @media (max-width: 768px) {
              height: auto;
              min-height: 70vw;
            }
            img {
              width: 10.5vw;
              height: 16.8vw;
            }
            h1 {
              width: auto;
              font-size: 3.7vw;
              line-height: normal;
            }
            p {
              font-size: 3.7vw;
              width: auto;
              line-height: 5.6vw;
              margin-top: 3.7vw;
            }
          }
        }
      }
      .footer-intro {
        width: 58.28vw;
        margin: auto;
        margin-bottom: 3.33vw;
        text-align: center;
        @media (max-width: 768px) {
          width: auto;
          padding: 0 6.4vw;
          text-align: left;
        }
      }
    }
  }
`;

const StyledText = styled.div`
  width: ${(props) => props.width};
  @media (max-width: 768px) {
    width: auto;
    padding: 0 6.4vw;
  }
  h1 {
    margin-top: 1.3vw;
    margin-bottom: 1.25vw;
    @media (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 3.5vw;
    }
  }
  .asset-p-text {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    font-weight: 700;
    @media (max-width: 768px) {
      font-weight: 400;
      gap: 7vw;
    }
  }
`;

export default About;
