import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import ScrollAnimate from "./animations/ScrollAnimate";

const Line = (props) => {
  return (
    <ScrollAnimate
      active={{
        width: "6.88vw",
        transition: {
          duration: 0.5,
        },
      }}
      inActive={{
        width: 0,
        transition: {
          duration: 0.5,
        },
      }}
    >
      <StyledLine {...props}></StyledLine>
    </ScrollAnimate>
  );
};
const StyledLine = styled.div`
  /* width: 6.88vw; */
  height: 0.52vw;
  background: ${(props) => props.fill || colors.mainYellow};
  @media (max-width: 768px) {
    height: 1vw;
  }
`;

export default Line;
