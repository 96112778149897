import React from "react";
import Blog from "../components/home/Blog";
import HappyCustomers from "../components/home/HappyCustomers";
import Intro from "../components/home/Intro";
import NewsLetter from "../components/home/NewsLetter";
import OurServices from "../components/home/OurServices";
import OurStory from "../components/home/OurStory";
import Projects from "../components/home/Projects";
import Quotation from "../components/home/Quotation";
import SpreeSolutions from "../components/home/SpreeSolutions";
import WhyUs from "../components/home/WhyUs";

const Home = () => {
  return (
    <div>
      <Intro />
      <WhyUs />
      <OurStory />
      <OurServices />
      {/* <SpreeSolutions /> */}
      <Projects />
      {/* <HappyCustomers /> */}
      <Quotation />
      <Blog />
      <NewsLetter />
    </div>
  );
};

export default Home;
