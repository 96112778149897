import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Button from "../assets/Button";
import ServicesCarousel from "../assets/ServicesCarousel";
import { Link } from "react-router-dom";
import { servicesDetailed } from "../../util/data";
import ServiceCard from "../assets/ServiceCard";
const OurServices = () => {
  return (
    <StyledSection>
      <h1 className="fs67 fs32m fw700 s-heading">Our Services</h1>
      <p className="fs27 fs14m services-paragraph">
        We are committed to producing high-quality, affordable software using
        the latest cutting-edge technologies and the best design practices.
      </p>
      <ServicesCarousel
        className="desktop-only"
        briefView
        svgImages
        services={servicesDetailed}
      />
      <div className="mobile-services mobile-only">
        {servicesDetailed.map((service) => (
          <ServiceCard
            className="mobile-home-card"
            svgImages
            briefView
            onClick={() => null}
            key={service.name}
            service={service}
          />
        ))}
      </div>
      <Link to="/services">
        <Button>More Details</Button>
      </Link>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  background: ${colors.mainBlue};
  max-width: 100vw;
  height: 50vw;
  max-height: 100vh;
  padding: 2vh 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  @media (max-width: 768px) {
    height: auto;
    padding: 19.2vw 0vw 10.13vw;
  }
  .s-heading,
  .services-paragraph {
    color: white;
    text-align: center;
    align-self: center;
    @media (max-width: 768px) {
      text-align: left;
      padding: 0 6.4vw;
    }
  }
  .s-heading {
    margin-bottom: 1vh;
    @media (max-width: 768px) {
      margin-bottom: 4.3vw;
    }
  }
  .services-paragraph {
    width: 58.6vw;
    /* margin: auto; */
    margin-bottom: 2.1vw;
    @media (max-width: 768px) {
      width: auto;
      margin-bottom: 8.7vw;
    }
  }

  button {
    display: block;
    margin: auto;
    margin-top: 3vh;
    @media (max-width: 768px) {
      margin-top: 8.5vw;
    }
  }
  .mobile-services {
    display: flex;
    gap: 3.25vw;
    max-width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    padding: 0 6.4vw;
    .mobile-home-card {
      min-width: 34.75vw;
      max-width: 34.75vw;
      height: 42.8vw;
      gap: 3.7vw;
      padding: 5.8vw;
      border-radius: 2.9vw;
      h1 {
        font-size: 3.2vw;
        /* flex: 1; */
        line-height: 3.7vw;
        width: auto;
      }
    }
  }
`;
export default OurServices;
