import React from "react";
import styled from "styled-components";
import { ReactComponent as Illustration } from "../../images/whyusillustration.svg";
import colors from "../../util/colors";
import { ReactComponent as Ill1 } from "../../images/why-us/1.svg";
import { ReactComponent as Ill2 } from "../../images/why-us/2.svg";
import { ReactComponent as Ill3 } from "../../images/why-us/3.svg";
import { ReactComponent as Ill4 } from "../../images/why-us/4.svg";
import ScrollAnimate from "../assets/animations/ScrollAnimate";

const WhyUs = () => {
  const whyUs = [
    { text: "We are AFFORDABLE", image: <Ill3 /> },
    { text: "We are FLEXIBLE", image: <Ill2 /> },
    { text: "We are AVAILABLE", image: <Ill4 /> },
    { text: "We are RELIABLE", image: <Ill1 /> },
  ];
  return (
    <StyledSection>
      <h1>Why Spree?</h1>
      <div className="why-us-grid">
        {whyUs.map((item) => (
          <WhyUsCard text={item.text} image={item.image} />
        ))}
      </div>
      <Illustration className="illust" />
    </StyledSection>
  );
};

export const WhyUsCard = ({ text, image: Image, ...props }) => {
  return props.aboutView ? (
    <ScrollAnimate>
      <StyledCard2 {...props}>
        <p className="fs38 fs18m fw700">{text}</p>
      </StyledCard2>
    </ScrollAnimate>
  ) : (
    <ScrollAnimate>
      <StyledCard {...props}>
        <p className="fs32 fs16m fw700">{text}</p>
        {Image}
      </StyledCard>
    </ScrollAnimate>
  );
};
const StyledSection = styled.div`
  height: 67.34vw;
  padding: 4.7vw 7.27vw 0vw 7.1vw;
  display: flex;
  gap: 11.54vw;
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 21.86vw 6.4vw 0;
    flex-direction: column;
    gap: 8.5vw;
  }
  h1 {
    width: 12.8vw;
    margin-top: 4.7vw;
    font-size: 4vw;
    @media (max-width: 768px) {
      width: 29.9vw;
      margin-top: 0;
      font-size: 8.5vw;
    }
  }
  .why-us-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: flex-start;
    grid-gap: 4.3vw 2.9vw;
    @media (max-width: 768px) {
      width: 100%;
      grid-gap: 5.1vw 4.91vw;
    }
  }
  .illust {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 33.2vw;
    height: 38.37vw;
    z-index: -1;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const StyledCard = styled.div`
  min-width: 17.9vw;
  height: 18.9vw;
  background: white;
  border-radius: 0.57vw;
  box-shadow: 0.52vw 0.677vw 0.833vw rgba(0, 0, 0, 0.16);
  padding: 2.8vw 0.9vw;
  border: solid 0.52vw ${colors.mainBlue};
  display: flex;
  flex-direction: column;
  gap: 1.1vw;
  align-items: center;
  @media (max-width: 768px) {
    width: auto;
    height: 45vw;
    border-radius: 1.1vw;
    box-shadow: 2.7vw 3.5vw 4.3vw rgba(0, 0, 0, 0.16);
    border: solid 1.1vw ${colors.mainBlue};
    gap: 6.13vw;
  }
  p {
    color: ${colors.mainBlue};
    text-align: center;
    max-width: 13.3vw;
    @media (max-width: 768px) {
      max-width: 34.4vw;
    }
  }
  svg {
    width: 7vw;
    height: 7vw;
    @media (max-width: 768px) {
      width: 11.2vw;
      height: 11.2vw;
    }
  }
`;

const StyledCard2 = styled.div`
  min-width: 17.9vw;
  height: 18.9vw;
  background: ${colors.mainBlue};
  border-radius: 0.57vw;
  box-shadow: 0.52vw 0.677vw 0.833vw rgba(0, 0, 0, 0.16);
  padding: 2.8vw 4vw;
  border: solid 0.52vw ${colors.mainBlue};
  display: flex;
  flex-direction: column;
  gap: 1.1vw;
  align-items: center;
  justify-content: center;
  p {
    /* color: ${colors.mainBlue}; */
    color: white;
    text-align: center;
    max-width: 13.3vw;
  }
  svg {
    width: 7vw;
    height: 7vw;
  }
`;
export default WhyUs;
