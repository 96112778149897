import { getNodeText } from "@testing-library/react";
import { getNextKeyDef } from "@testing-library/user-event/dist/keyboard/getNextKeyDef";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
const BubbleStepper = ({
  items,
  diameter,
  gap,
  thickness,
  animationDuration = "0.5s",
  active,
  setActive,
}) => {
  const [auto, setAuto] = useState(true);
  useEffect(() => {
    const timer = window.setInterval(() => {
      console.log(active);
      if (active < items.length - 1) {
        setActive((prev) => prev + 1);
      } else {
        setActive(0);
      }
    }, 5000);
    if (!auto) window.clearInterval(timer);
    return () => {
      window.clearInterval(timer);
    };
  }, [active, auto]);
  return (
    <StyledStepper
      diameter={diameter}
      gap={gap}
      height={items.length * diameter + (items.length - 1) * gap}
      thickness={thickness}
      items={items || []}
      active={active}
      animationDuration={animationDuration}
      className="dev-stepper"
    >
      <div className="dev-stepper-container-container">
        <div className="dev-stepper-container">
          {items.map((item, index) => (
            <div
              className="stepper-item"
              key={item.name}
              onClick={() => {
                setAuto(false);
                setActive(index);
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className={`step-circle ${`index${index}`} ${
                  active >= index ? "active" : ""
                }`}
              >
                <item.illustration />
              </div>
              <p style={{ cursor: "pointer" }} className="fs33 fs16m fw700">
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </StyledStepper>
  );
};

const StyledStepper = styled.div`
  margin-left: 3vw;
  height: 100%;
  @media (max-width: 768px) {
    margin-left: 9vw;
  }
  .dev-stepper-container-container {
    width: ${(props) => `${props.thickness}vw`};
    height: ${(props) => `${props.height}vw`};
    /* height: fit-content; */
    background: ${colors.mainBlue};
  }
  .dev-stepper-container {
    position: relative;
    font-size: 1vw;
    width: ${(props) => `${props.thickness}vw`};
    height: ${(props) =>
      `${(props.active + 1) * props.diameter + props.active * props.gap}vw`};
    background-color: ${colors.mainYellow};
    transition: height ${(props) => props.animationDuration} ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: ${(props) => `${props.gap}vw`};
    .stepper-item {
      /* width: 30vw; */
      display: flex;
      align-items: center;
      position: relative;
      left: 50%;
      transform: translateX(-${(props) => `${props.thickness / 2}vw`});
      p {
        position: absolute;
        left: 110%;
        width: 12vw;
        @media (max-width: 768px) {
          width: max-content;
        }
      }
    }
    .step-circle {
      min-width: ${(props) => `${props.diameter}vw`};
      max-width: ${(props) => `${props.diameter}vw`};
      min-height: ${(props) => `${props.diameter}vw`};
      max-height: ${(props) => `${props.diameter}vw`};
      border-radius: 50%;
      background: white;
      border: solid ${(props) => `${props.thickness}vw`} ${colors.mainBlue};
      svg {
        max-width: 3vw;
        max-height: 3vw;
        object-fit: contain;
        * {
          fill: ${colors.mainYellow};
          transition: fill ${(props) => props.animationDuration};
        }
        @media (max-width: 768px) {
          max-width: 8vw;
          max-height: 8vw;
        }
      }
      &.active {
        background: ${colors.mainYellow};
        border: none;
        svg {
          * {
            fill: white;
          }
        }
      }
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default BubbleStepper;
