import React from "react";
import styled from "styled-components";
import bg from "../../images/introbg.jpg";
import { ReactComponent as Down } from "../../images/arrowdown.svg";
import colors from "../../util/colors";
import Button from "../assets/Button";
import Typewriter from "typewriter-effect";
import { Link as a } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Intro = () => {
  const navigate = useNavigate();
  return (
    <StyledIntro>
      <div className="col1">
        <p className="fw700 fs57 fs28m">
          We build great digital experience for your
          <span>
            <Typewriter
              options={{
                strings: [
                  "Bussiness Growth.",
                  "Technical Support.",
                  "Digital Journey.",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </span>
        </p>
        <div className="to-our-story fs30 fs18m">
          <a href="/#our-story">
            Our Story
            <Down />
          </a>
        </div>
      </div>
      <div className="col2">
        <div className="intro-form">
          <h1 className="fs45 fs24m  fw400">Let's start your</h1>
          <h1 className="fs45  fs24m fw700">Digital Journey!</h1>
          <div className="intro-form-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                navigate("/phone-call-request-success");
              }}
            >
              <div className="intro-input-div">
                <Circle />
                <input required type="text" placeholder="Full Name" />
              </div>
              <div className="intro-input-div">
                <Circle />
                <input required type="email" placeholder="Email" />
              </div>
              <div className="intro-input-div">
                <Circle />
                <input required type="text" placeholder="Phone Number" />
              </div>
              <Button>Request a phone call</Button>
            </form>
          </div>
        </div>
      </div>
    </StyledIntro>
  );
};

const StyledIntro = styled.div`
  padding: 0 6.2vw;
  /* min-height: 47.55vw; */
  height: calc(100vh - 6.5vw);
  background-color: white;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 0% 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding: 12.26vw 0 0;
    min-height: auto;
    height: auto;
    background-image: none;
    flex-direction: column;
    justify-content: flex-start;
  }
  .col1 {
    @media (max-width: 768px) {
      padding: 0vw 6.4vw 0;
    }
    p {
      width: 34vw;
      margin-top: 10.5vw;
      @media (max-width: 768px) {
        width: auto;
        margin-top: 0;
      }
    }
    .to-our-story {
      margin-top: 1.1vw;
      @media (max-width: 768px) {
        margin-top: 7.2vw;
      }
      a {
        display: flex;
        align-items: center;
        gap: 1.13vw;
        text-decoration: none;
        @media (max-width: 768px) {
          gap: 4.5vw;
        }
        svg {
          width: 1.45vw;
          @media (max-width: 768px) {
            width: 4.2vw;
          }
        }
      }
    }
  }
  .col2 {
    .intro-form {
      width: 42.65vw;
      height: 36.6vw;
      margin-top: 1vw;
      background: ${colors.mainBlue};
      border-radius: 0.6vw;
      padding: 1.8vw 1.9vw 2.6vw;
      @media (max-width: 768px) {
        width: auto;
        height: auto;
        margin-top: 13.1vw;
        border-radius: 2.93vw;
        padding: 11.73vw 6.4vw 10.67vw;
        box-shadow: 2.7vw 3.5vw 4.3vw rgba(0, 0, 0, 0.16);
      }
      h1 {
        color: white;
        text-align: center;
      }
      .intro-form-container {
        width: 38.7vw;
        padding-bottom: 2vw;
        background: white;
        border-radius: 0.5vw;
        margin-top: 1.1vw;
        padding-top: 2.3vw;
        @media (max-width: 768px) {
          width: auto;
          height: auto;
          border-radius: 2.4vw;
          margin-top: 10.13vw;
          padding: 11.73vw 2.1vw 3.2vw;
        }
        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.04vw;
          @media (max-width: 768px) {
            gap: 8.53vw;
          }
          .intro-input-div {
            position: relative;
            width: 35.4vw;
            height: 3.9vw;
            @media (max-width: 768px) {
              width: 100%;
              height: 12.8vw;
            }
            input {
              &::placeholder {
                font-size: 1.6vw;
                color: #d9d8db;
                @media (max-width: 768px) {
                  font-size: 4.3vw;
                }
              }
              padding-left: 4.42vw;
              height: 100%;
              width: 100%;
              border: none;
              outline: none;
              box-shadow: 0 0.26vw 1.04vw rgba(0, 0, 0, 0.16);
              @media (max-width: 768px) {
                padding-left: 11.73vw;
                box-shadow: 0 1.3vw 5.3vw rgba(0, 0, 0, 0.16);
              }
            }
          }
          button {
            margin-top: 1.56vw;
            gap: 0;
            @media (max-width: 768px) {
              margin-top: 5.34vw;
              width: 100%;
              height: 15vw;
            }
          }
        }
      }
    }
  }
`;

const Circle = styled.div`
  width: 2.2vw;
  height: 2.2vw;
  border-radius: 50%;
  background-color: #932fff;
  opacity: 0.47;
  position: absolute;
  left: 1.35vw;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 768px) {
    width: 4.53vw;
    height: 4.53vw;
    left: 4vw;
  }
`;
export default Intro;
