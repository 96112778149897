import React from "react";
import styled from "styled-components";
import ws1 from "../../images/blogs/ws1.png";
import ws2 from "../../images/blogs/ws2.png";
import ws3 from "../../images/blogs/ws3.png";

const WhyWebsite = () => {
  return (
    <StyledUU>
      <div className="uu-header">
        <h1 className="fs47 fs32m algcenter">
          Why your business should go online?
        </h1>
        <h1 className="fs23 fs12m algcenter">25/06/2022</h1>
      </div>
      <div className="t-container text-1-container fs32 fs16m">
        <div className="left">
          <p>
            In the digital age, your business needs to have an online presence.
            Whether it’s a website, an e-commerce platform, a social media page,
            or a combination of all three, getting your company online will reap
            major benefits. Even if your company does not conduct business
            online, customers and potential customers are expecting to see you
            online. If they don’t see you there, you could be losing out on the
            opportunity to increase your customer base and get the word out
            about your business.
          </p>
          <h3>1. Easier for Potential Customers to Find You</h3>
          <p>
            If someone wants more information about a company, they’re most
            likely to do their research online. Whether they’re specifically
            looking for your company, or they just want to find any company that
            offers the products or services that your company offers, having an
            online presence will give you a competitive edge. Potential
            customers will not put a lot of effort into finding you, and they
            should not have to. A simple Google search should provide them with
            all the information they seek.
          </p>
          <h3>2. Easier to Showcase Your Products and Services </h3>
          <p>
            The Internet gives businesses an effective platform for showcasing
            what they have to offer. Whether it’s a portfolio and testimonials
            from clients on a website or an album on a Facebook page with photos
            of your newest products, it has never been easier to let the world
            know what you have to offer. With a few simple clicks, your
            customers can see what you’re all about. They can even do this
            outside of business hours! An online presence is an extension of
            your brand that never sleeps.
          </p>
        </div>
        <div className="right">
          <img src={ws1} alt="" />
        </div>
      </div>
      <div className="t-container text-2-container fs32 fs16m">
        <div className="left">
          {/* <div> */}
          <h3>
            3. Easier to Build Relationships with Customers and Potential
            Customers
          </h3>
          <p>
            Social media is all about building relationships. This is true for
            both individuals and businesses. Social media gives your brand a
            voice – it makes your company more “human” and relatable. Customers
            and potential customers can interact with your brand on a more
            personal level. It also allows you to truly get to know your
            customers. If everyone is on social media except you, you are
            missing out on an invaluable opportunity to connect and communicate
            with your target audience. Social media is one of the simplest, yet
            most effective ways to get persons interested in your company and to
            form real relationships with real people.
          </p>
          <h3>4. Easier to Market Your Brand</h3>
          <p>
            Websites and social media platforms are excellent marketing tools.
            They are also some of the most cost-effective methods of sending out
            information to thousands of people. Online marketing is extremely
            important for all businesses because it has a huge influence on the
            way consumers make purchasing decisions. Modern consumers have.
          </p>
          {/* </div> */}
          {/* <p className="fw700">
            2. A UX designer is concerned with the conceptual aspects of the
            design process, leaving the UI designer to focus on the more
            tangible elements - Andy Budd – Co-founder of Clearleft, Founder of
            UX London
          </p>
          <p className="fw700">
            3. UI is focused on the product, a series of snapshots in time. UX
            focuses on the user and their journey through the product - Scott
            Jenson – former Product Strategist at Google
          </p> */}
        </div>
        <div className="right">
          <img src={ws2} alt="" />
          <img className="end-image" src={ws3} alt="" />
        </div>
      </div>
    </StyledUU>
  );
};
const StyledUU = styled.div`
  padding: 8vw;
  display: flex;
  flex-direction: column;
  gap: 3vw;
  @media (max-width: 768px) {
    gap: 6vw;
  }
  .t-container {
    display: flex;
    gap: 3vw;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &.text-1-container {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 3vw;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 25vw;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    &.text-2-container {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      img {
        width: 25vw;
        height: 25vw;
        object-fit: cover;
        object-position: 60% 10%;
        margin-top: 7vw;
        @media (max-width: 768px) {
          /* display: none; */
          width: 100%;
          height: auto;
        }
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 3vw;
      }
    }
    &.text-3-container {
      .left {
        img {
          width: 25vw;
          height: 25vw;
          object-fit: cover;
          @media (max-width: 768px) {
            /* display: none; */
            width: 100%;
            height: auto;
          }
        }
      }
    }
    &.text-4-container {
      flex-direction: column;
    }
  }
`;

export default WhyWebsite;
