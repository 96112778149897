import React from "react";
import styled from "styled-components";
import ScrollAnimate from "./animations/ScrollAnimate";

const EntityCard = ({ name, image: Image }) => {
  return (
    <ScrollAnimate>
      <StyledCard>
        <Image />
        <p className="fs20 fw700 fs12m">{name}</p>
      </StyledCard>
    </ScrollAnimate>
  );
};
const StyledCard = styled.div`
  width: 17.52vw;
  height: 9.9vw;
  box-shadow: 0 0.16vw 0.3vw rgba(0, 0, 0, 0.16);
  padding: 1.95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.78vw;
  @media (max-width: 768px) {
    width: auto;
    height: 20.9vw;
    border-radius: 4vw;
    box-shadow: 0 0.8vw 1.6vw rgba(0, 0, 0, 0.16);
  }
  p {
    text-align: center;
  }
  img {
    width: 7.55vw;
    height: 4.9vw;
    object-fit: contain;
    @media (max-width: 768px) {
      width: 14vw;
      height: 10vw;
    }
  }
`;
export default EntityCard;
