import React, { useState } from "react";
import styled from "styled-components";
import logo from "../../images/logo.png";
import colors from "../../util/colors";
import { ReactComponent as Git } from "../../images/getintouch.svg";
import { Link, useLocation } from "react-router-dom";
import Button from "../assets/Button";
import { ReactComponent as Menu } from "../../images/icons/menu.svg";
import { ReactComponent as Close } from "../../images/icons/close.svg";
import { ReactComponent as Right } from "../../images/icons/right.svg";
import { keyframes } from "styled-components";
export const navLinks = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Services", path: "/services" },
  // { name: "Solutions", path: "/solutions" },
  { name: "Projects", path: "/projects" },
  { name: "Blog", path: "/blog" },
  { name: "Careers", path: "/careers" },
];
const Nav = () => {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  return (
    <StyledNav className="border-btm">
      <Link to="/">
        <img className="logo" src={logo} alt="spree-logo" />
      </Link>
      <Button className="get-in-touch mobile-only">
        <Git className="git" />
        <Link to="/contact-us" className="fs20 fs12m fw700">
          Get in Touch
        </Link>
      </Button>
      <div className="mobile-only mobile-menu">
        <button onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? (
            <Close className="close-btn" />
          ) : (
            <Menu className="menu-btn" />
          )}
        </button>
        <div className={`links-list-mobile ${isVisible ? "active" : ""}`}>
          {navLinks.map((link) => (
            <Link
              key={link.name + "mobile"}
              to={link.path}
              onClick={() => setIsVisible(false)}
              className="links-list-item fw700"
            >
              <p>{link.name}</p>
              <Right className="right-btn" />
            </Link>
          ))}
        </div>
      </div>
      <div className="nav-links">
        {navLinks.map((link) => (
          <Link
            key={link.name}
            to={link.path}
            className={`nav-link fw700 fs20 ${
              pathname === link.path && "selected"
            }`}
          >
            {link.name}
          </Link>
        ))}
      </div>
      <Button className="get-in-touch">
        <Git className="git" />
        <Link to="/contact-us" className="fs20 fw700">
          Get in Touch
        </Link>
      </Button>
    </StyledNav>
  );
};
const git = keyframes`
    0% {
      transform :scale(1);
      opacity: 1;
    }
    /* 50%{
      transform:  scale(1.1);
      opacity: 0.5;
    } */
    100% {
      transform :scale(1.2);
      opacity: 0.5;
    }
`;
const StyledNav = styled.div`
  height: 6.5vw;
  padding-left: 2.76vw;
  padding-right: 3.76vw;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    height: 21.33vw;
    padding: 0 6.4vw 0 0;
    justify-content: space-between;
    position: relative;
    .mobile-menu {
      .links-list-mobile {
        z-index: 3000000;
        background: white;
        height: 100vh;
        /* border: solid 2px black; */
        position: absolute;
        top: 100%;
        /* right: -100%; */
        right: 0;
        width: 0;
        overflow: hidden;
        transition: width 0.2s ease-out;
        &.active {
          width: 80vw;
          border: solid 0.026vw #bebaba;
          /* box-shadow: -2vw 0vw 3vw rgba(0, 0, 0, 0.16); */
        }
        .links-list-item {
          height: 20vw;
          padding: 0 3vw;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.09vw solid #cecece;
          svg {
            width: 4vw;
          }
        }
      }
      button {
        background: none;
        border: none;
        outline: none;
        svg {
          .menu-btn {
            width: 4.3vw;
            height: 3.2vw;
          }
          .close-btn {
            width: 5vw;
            height: 5vw;
          }
        }
      }
    }
  }
  .logo {
    width: 12.8vw;
    margin-right: 2.3vw;
    @media (max-width: 768px) {
      width: 37.6vw;
      margin-right: 0;
    }
  }
  .nav-links {
    display: flex;
    gap: 1.6vw;
    margin-right: 0;
    margin-left: auto;
    @media (max-width: 768px) {
      display: none;
    }
    .nav-link {
      text-decoration: none;
      &.selected {
        color: ${colors.mainYellow};
      }
    }
  }
  .get-in-touch {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14.95vw;
    height: 3vw;
    gap: 1.54vw;
    background-color: ${colors.mainYellow};
    border-radius: 0.78vw;
    margin-left: 3.02vw;
    @media (max-width: 768px) {
      display: none;
      &.mobile-only {
        display: flex;
        width: 40%;
        height: 50%;
        border-radius: 4vw;
        svg {
          width: 4vw;
          height: 4vw;
        }
      }
    }
    svg {
      width: 2vw;
      height: 2vw;
      animation: ${git} 1s infinite alternate;
    }
    a {
      text-decoration: none;
      color: white;
      display: block;
    }
  }
`;

export default Nav;
