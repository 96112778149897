import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import { motion } from "framer-motion";
const Button = ({ children, ...props }) => {
  return (
    <StyledButton
      whileHover={{
        scale: 1.1,
        transition: { duration: 0.2 },
      }}
      whileTap={{ scale: 0.9 }}
      className={`fs23 fs16m fw700 ${props.className || ""}`}
      {...props}
    >
      {children}
    </StyledButton>
  );
};
const StyledButton = styled(motion.button)`
  height: 3.54vw;
  min-width: 15.76vw;
  background-color: ${colors.mainYellow};
  color: white;
  border: none;
  outline: none;
  border-radius: 0.78vw;
  padding: 0.7vw;
  @media (max-width: 768px) {
    height: 11.73vw;
    min-width: 58vw;
    border-radius: 4vw;
    padding: 2.6vw;
  }
  a {
    text-decoration: none;
    color: white;
  }
`;
export default Button;
