import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Line from "../assets/Line";
import { ReactComponent as I } from "../../images/about/cuillust.svg";
import { ReactComponent as Cnf } from "../../images/services/confirm.svg";
import { formatString } from "../../util/util";
import Button from "../assets/Button";
import wa from "../../images/about/wa.png";
import fb from "../../images/about/fb.png";
import { useLocation } from "react-router-dom";
const ContactUs = () => {
  const [success, setSuccess] = useState(false);
  const contactFormFields = [
    { name: "name", displayName: "Full Name" },
    {
      name: "email",
    },
    {
      name: "phone",
    },
    // {
    //   name: "location",
    // },
    { name: "message", type: "textarea", displayName: "Write your message.." },
  ];
  const submitHandler = () => {
    setSuccess(true);
  };
  return (
    <StyledSection className="contact-us-form-container">
      <I className="illust" />
      <div className="col1">
        <Line />
        <h1 className="fs70 fs24m">
          {success
            ? "Thank you For your interest in Spree!"
            : "Contact us today!"}
        </h1>
        <p className="fs20 fs14m">
          We Help You Achieve Outstanding Business Results & Gain Competitive
          Advantage
        </p>
      </div>
      <div className="col2">
        <div className="contact-form">
          {success ? (
            <div className="success-message">
              <h1 className="fs40 fs16m algcenter">
                YOUR REQUEST HAS BEEN SUCCESSFULY SENT
              </h1>
              <Cnf />
              <p className="fs23 fs12m algcenter">
                We will get back to you as soon as possible! Stay on the lookout
                for our reply within the next few days{" "}
              </p>
            </div>
          ) : (
            <form onSubmit={submitHandler} className="fs27" id="c-form">
              {contactFormFields.map((field) =>
                field.type === "textarea" ? (
                  <textarea
                    required
                    name={field.name}
                    placeholder={field.displayName || formatString(field.name)}
                  ></textarea>
                ) : (
                  <input
                    required
                    type="text"
                    name={field.name}
                    placeholder={field.displayName || formatString(field.name)}
                  />
                )
              )}
            </form>
          )}
        </div>
        {!success && (
          <Button type="submit" form="c-form">
            LET'S SPREE!
          </Button>
        )}
        {!success && (
          <div className="sm-icons">
            <a href="https://m.me/103314712181043"><img src={fb} alt="fb" /></a>
            <img src={wa} alt="wa" />
          </div>
        )}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  position: relative;
  height: 50vw;
  background: ${colors.mainBlue};
  padding: 4.3vw 12.9vw 7.7vw 4.3vw;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 8vw 25vw 14vw 8vw;
  }
  svg.illust {
    position: absolute;
    top: 5.16vw;
    left: 15.5vw;
    width: 29.4vw;
    height: 22vw;
    opacity: 0.19;
  }
  .col1 {
    h1,
    p {
      color: white;
      width: 21.4vw;
      @media (max-width: 768px) {
        width: auto;
      }
    }
    h1 {
      line-height: 4.1vw;
      margin-top: 1.6vw;
      margin-bottom: 1vw;
      width: 20.8vw;
      @media (max-width: 768px) {
        line-height: 6vw;
        margin-top: 4vw;
        margin-bottom: 3vw;
      }
    }
  }
  .col2 {
    .contact-form {
      .success-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4vw;
        svg {
          /* position: static; */
          width: 4vw;
          height: auto;
          @media (max-width: 768px) {
            width: 12vw;
          }
          path {
            fill: green;
          }
        }
      }
      background-color: white;
      width: 36.9vw;
      /* height: 35.6vw; */
      padding: 1.72vw 2.82vw;
      border-radius: 0.46vw;
      @media (max-width: 768px) {
        margin-top: 4vw;
        width: auto;
        border-radius: 1.5vw;
        padding: 4vw 6vw;
      }

      input,
      textarea {
        height: 3.44vw;
        width: 100%;
        border: none;
        outline: none;
        margin-bottom: 0.9vw;
        box-shadow: 0 0.26vw 1.04vw rgba(0, 0, 0, 0.16);
        padding-left: 2.6vw;
        @media (max-width: 768px) {
          height: 12vw;
          margin-bottom: 4vw;
        }
      }
      textarea {
        height: 12.3vw;
        padding-top: 1vw;
        @media (max-width: 768px) {
          height: 24vw;
        }
      }
    }
    button {
      margin-top: 1.93vw;
      float: right;
      min-width: fit-content;
      @media (max-width: 768px) {
        margin-top: 6vw;
      }
    }
  }
  .sm-icons {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 100%;
    margin-left: 4.2vw;
    bottom: 10vw;
    gap: 1.1vw;
    img {
      width: 4.4vw;
      height: 4.4vw;
      object-fit: contain;
      @media (max-width: 768px) {
        width: 8vw;
        height: 8vw;
      }
    }
  }
`;
export default ContactUs;
