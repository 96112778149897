import React, { useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import { ReactComponent as Search } from "../images/icons/search.svg";
import { ReactComponent as Download } from "../images/icons/download.svg";
import { Link } from "react-router-dom";
import Button from "../components/assets/Button";
import ProjectsContainer from "../components/projects/ProjectsContainer";
import HappyCustomers from "../components/home/HappyCustomers";
import cp from "../util/cp.pdf";
const Projects = () => {
  const [selected, setSelected] = useState(0);
  const filterOptions = [
    { name: "All" },
    { name: "Web App" },
    { name: "Mobile App" },
    { name: "UI/UX" },
    { name: "SEO" },
    { name: "Other" },
  ];
  return (
    <StyledPage>
      <div className="header-project-page">
        <div className="row1">
          <div className="col1">
            <h1>Projects</h1>
            <h2 className=" fs16m fw400">WE BRING YOUR VISION TO REALITY</h2>
          </div>
          <div className="col2">
            {/* <Link  to="/"> */}
            <a className="download-btn" href={cp} download>
              <Button>
                Download our Company Profile <Download />
              </Button>
            </a>
            {/* </Link> */}
            <p className="fs20">Never miss a post!</p>
            {/* <form>
              <input
                className="fs20 fs14m"
                type="text"
                placeholder="Search our projects"
              />
              <Search />
              <button className="fs20 fw700">Subscribe</button>
            </form> */}
          </div>
        </div>
        <div className="filter-project">
          {filterOptions.map((f, index) => (
            <div
              onClick={() => setSelected(index)}
              className={`${
                selected === index ? "selected" : ""
              } filter-option fs20 fs12m fw700m`}
              key={f.name}
            >
              {f.name}
            </div>
          ))}
        </div>
      </div>
      <ProjectsContainer selectedCategory={filterOptions[selected]} />
      {/* <BlogsFooter /> */}
      {/* <HappyCustomers /> */}
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .header-project-page {
    /* height: 24.3vw; */
    background: white;
    padding: 2.9vw 11.8vw 0;
    @media (max-width: 768px) {
      height: auto;
      padding: 19.2vw 6.4vw 14.7vw;
    }

    .row1 {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .col1 {
        h1 {
          font-size: 6vw;
          line-height: 6vw;
          margin-bottom: 1.7vw;
          @media (max-width: 768px) {
            font-size: 12.8vw;
            line-height: 12.8vw;
          }
        }
        h2 {
          font-size: 2.18vw;
        }
      }
      .col2 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (max-width: 768px) {
          align-items: flex-start;
          margin-top: 12vw;
        }

        p {
          color: white;
        }
        form {
          position: relative;
          margin-top: 0.89vw;
          @media (max-width: 768px) {
            margin-top: 9vw;
            width: 100%;
          }

          svg {
            display: block;
            width: 2vw;
            height: 2vw;
            position: absolute;
            right: 1vw;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 768px) {
              width: 5vw;
              height: 6vw;
              right: 3vw;
            }
          }
          input {
            display: block;
            outline: none;
            border: solid 0.05vw #707070;
            width: 22.55vw;
            height: 2.8vw;
            border-radius: 0.8vw;
            padding-right: 4vw;
            padding-left: 1.4vw;
            @media (max-width: 768px) {
              width: 100%;
              height: 11.7vw;
              border-radius: 1.9vw;
              padding-right: 10vw;
              padding-left: 4vw;
            }
            &::placeholder {
              color: #d9d8db;
            }
          }
          /* button {
            position: absolute;
            right: 0.52vw;
            top: 50%;
            transform: translateY(-50%);
            color: #932fff;
            background: none;
            border: none;
            outline: none;
          } */
        }
        .download-btn {
          display: flex;
          align-items: center;
          svg {
            /* width: 1.6vw; */
            margin-left: 1vw;
            height: 60%;
            /* flex: 1; */
            fill: white;
            display: inline-block;
          }
        }
      }
    }
    .filter-project {
      display: flex;
      width: 100%;
      margin-top: 4.56vw;
      @media (max-width: 768px) {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        gap: 1.9vw;
        overflow: scroll;
        margin-top: 6vw;
      }
      .filter-option {
        background: white;
        width: 11.13vw;
        flex: 1;
        border: solid 0.2vw #f2f1f7;
        height: 3.7vw;
        border-radius: 1vw 1vw 0vw 0vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: 768px) {
          min-width: 21.86vw;
          height: 11.7vw;
          border-radius: 4vw;
        }
        &.selected {
          background: #f2f1f7;
        }
      }
    }
  }
  .happy-customers-section {
    padding-top: 1.5vw;
    padding-bottom: 5vw;
    .happy-customers-container {
      background-color: white;
    }
    .review-card {
      outline: 1.75vw solid ${colors.mainBlue};
    }
  }
`;
export default Projects;
