import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import blogbg from "../../images/blogbg.jpg";
import BlogPostCard from "../assets/BlogPostCard";
import Button from "../assets/Button";
import { blogPosts } from "../blog/BlogPosts";
const Blog = () => {
  return (
    <StyledSection>
      <div className="blog-section-header">
        <h1 className="fw700">Blog</h1>
        <Link to="/blog">
          <Button>Be in the Know</Button>
        </Link>
      </div>
      <div className="blog-posts-container">
        {blogPosts.map((post) => (
          <BlogPostCard
            key={Math.random(17387232)}
            title={post.title}
            text={post.text}
            image={post.image}
            link={post.link}
          />
        ))}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  padding: 3.3vw 6.5vw 6.65vw 6.5vw;
  @media (max-width: 768px) {
    padding: 13.33vw 6.4vw 0vw;
  }
  .blog-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 3vw;
    }
    h1 {
      font-size: 6.1vw;
      @media (max-width: 768px) {
        font-size: 8.5vw;
      }
    }
  }
  .blog-posts-container {
    margin-top: 7.6vw;
    padding: 0 1.33vw;
    display: flex;
    gap: 6.4vw;
    @media (max-width: 768px) {
      margin-top: 4.267vw;
      padding: 0;
      flex-direction: column;
      gap: 4.27vw;
    }
  }
`;

export default Blog;
