import React from "react";
import styled from "styled-components";
import Button from "../assets/Button";
import { ReactComponent as Illustration } from "../../images/quotationillust.svg";

const QuotationHeader = () => {
  return (
    <StyledSection>
      <Illustration className="svg1" />
      <h1 className="htitle">Get a free Quotation</h1>
      <p className="fs30 fs14m">Partner with Spree today</p>
      <a href="#quotation-form">
        <Button>Get Started</Button>
      </a>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  padding: 2.9vw 0 4vw 9.8vw;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 9.3vw 0 22.8vw;
    align-items: center;
  }
  .svg1 {
    width: 26.6vw;
    height: 35vw;
    position: absolute;
    top: 1.2vw;
    right: 6.2vw;
    @media (max-width: 768px) {
      width: 36.9vw;
      height: 48.5vw;
      position: static;
    }
  }
  .htitle {
    width: 30.7vw;
    font-size: 6.1vw;
    line-height: 6vw;
    @media (max-width: 768px) {
      width: 66.7vw;
      font-size: 12.8vw;
      line-height: 12.8vw;
    }
  }
  p {
    margin-bottom: 1.7vw;
    @media (max-width: 768px) {
      margin-bottom: 4.77vw;
    }
  }
  button {
    min-width: 34vw;
  }
`;

export default QuotationHeader;
