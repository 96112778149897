import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Illustration } from "../../images/ourstoryillustration.svg";
import illustration from "../../images/ourstoryillustration.png";
import Button from "../assets/Button";
import Line from "../assets/Line";

const OurStory = () => {
  return (
    <StyledSection id="our-story">
      <Line />
      <h1 className="fs67 fs32m fw700">Our Story</h1>
      <div className="story-container">
        <p className="fs30 fs14m">
          Spree started as a fashion platform that combines all fashion brands
          in one program. We wanted to fulfill the market gaps, and with our
          vision in technology, we can change the way we interact with things in
          our messy and busy life. We decided why to create only one platform
          when we can create many and help others. At first, the idea was the
          transformation of fashion, then the realization of transforming real
          apps and shopping cycles with spree and joy. Why settle for one when
          you can contribute to all?
        </p>
        {/* <Illustration /> */}
        <img className="illustration" src={illustration} alt="illust" />
        <Link to="/about">
          <Button>More about Spree</Button>
        </Link>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  height: 44.75vw;
  padding: 7.5vw 0 0 8.6vw;
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 20.1vw 6.4vw 20.1vw;
  }
  h1 {
    margin-top: 2vw;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  .story-container {
    width: 59vw;
    @media (max-width: 768px) {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    button {
      margin-top: 2vw;
      /* margin-left: 37.8vw; */
      @media (max-width: 768px) {
        margin: 0;
        margin: auto;
      }
    }
  }
  svg,
  .illustration {
    width: 21.8vw;
    height: 33.66vw;
    position: absolute;
    top: -20.8%;
    right: 9.77vw;
    @media (max-width: 768px) {
      position: static;
      display: block;
      width: 26.8vw;
      height: 41.456vw;
      margin: 12.5vw auto 11.33vw;
    }
  }
`;
export default OurStory;
