import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import project from "../../images/projectimage.jpg";
import { Link } from "react-router-dom";
import ScrollAnimate from "./animations/ScrollAnimate";

const ProjectCard = () => {
  return (
    <ScrollAnimate>
      <StyledCard>
        <div className="project-card-header">
          <div className="client-image"></div>
          <p className="fs25 fw700 client-name">Client Name</p>
        </div>
        <img src={project} alt="" />
        <div className="project-link">
          <Link to="/">Visit Website</Link>
        </div>
      </StyledCard>
    </ScrollAnimate>
  );
};
const StyledCard = styled.div`
  width: 17.27vw;
  height: 23.12vw;
  border-radius: 0.57vw;
  background: ${colors.mainBlue};
  display: flex;
  flex-direction: column;
  .project-card-header {
    height: 6.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.46vw;
    .client-image {
      width: 2.86vw;
      height: 2.86vw;
      background: white;
      border-radius: 50%;
    }
    .client-name {
      color: white;
    }
  }
  img {
    width: 100%;
    height: 13.44vw;
    object-fit: cover;
  }
  .project-link {
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
      color: white;
      text-align: center;
      display: block;
      font-size: 1.14vw;
    }
  }
`;

export default ProjectCard;
