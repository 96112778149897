import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/assets/Button";
import { ReactComponent as Illustration } from "../images/quotationillust.svg";
import confirm from "../images/services/confirm.svg";

const Success = ({
  title,
  message = "YOUR REQUEST HAS BEEN SUCCESSFULY SENT",
  instructions,
}) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <StyledSection>
      <Illustration className="svg1" />
      <h1 className="htitle algcenter-mo">{title}</h1>
      <div className="message-div">
        <p className="fs30 fs14m">{message}</p>
        <img src={confirm} alt="" />
      </div>
      <p className="fs23 fs12m instructions">{instructions}</p>
      <Link to="/">
        <Button>Back to Home</Button>
      </Link>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  padding: 2.9vw 0 4vw 9.8vw;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 6.5vw);
  @media (max-width: 768px) {
    padding: 9.3vw 0 22.8vw;
    align-items: center;
  }
  .message-div {
    display: flex;
    align-items: center;
    gap: 2vw;
    margin: 1vw 0;
    width: 40vw;
    @media (max-width: 768px) {
      width: auto;
    }
    p {
      margin: 0;
    }
    img {
      width: 3vw;
      height: 3vw;
    }
  }
  .svg1 {
    width: 26.6vw;
    height: 35vw;
    position: absolute;
    top: 1.2vw;
    right: 6.2vw;
    @media (max-width: 768px) {
      width: 36.9vw;
      height: 48.5vw;
      position: static;
    }
  }
  .htitle {
    width: 30.7vw;
    font-size: 6.1vw;
    line-height: 6vw;
    @media (max-width: 768px) {
      width: 66.7vw;
      font-size: 12.8vw;
      line-height: 12.8vw;
    }
  }
  p {
    margin-bottom: 1.7vw;
    @media (max-width: 768px) {
      margin-bottom: 4.77vw;
    }
  }
  .instructions {
    width: 30vw;
    @media (max-width: 768px) {
      width: auto;
      width: 80%;
      text-align: center;
    }
  }
`;

export default Success;
