import React from "react";
import styled from "styled-components";
const ServiceCard = ({ service, briefView, svgImages, ...props }) => {
  return (
    <StyledCard briefView={briefView} {...props}>
      {svgImages ? (
        <service.image />
      ) : (
        <img src={service.image} alt={service.name} />
      )}
      <h1 className="fs37">{service.name}</h1>
      {!briefView && <p className="fs20">{service.text}</p>}
    </StyledCard>
  );
};

const StyledCard = styled.div`
  min-width: 23vw;
  max-width: 23vw;
  height: 26.7vw;
  background: white;
  border-radius: 0.57vw;
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6vw 1.8vw 2vw 1.8vw;
  h1,
  p {
    width: 17vw;
    text-align: center;
  }
  h1 {
    line-height: 2.5vw;
    margin-bottom: 0.833vw;
  }
  p {
    line-height: 1.6vw;
  }

  img,
  svg {
    height: 6vw;
    margin-bottom: 1.8vw;
    width: 100%;
    object-fit: contain;
    flex: ${(props) => (props.briefView ? 1 : "")};
    /* width: auto; */
    /* object-fit: contain; */
  }
`;
export default ServiceCard;
