import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import { formatString } from "../../util/util";
import Button from "../assets/Button";
import CheckSelect from "../assets/CheckSelect";

const QuotationForm = ({ setSuccess }) => {
  const fields1 = [
    { name: "name", displayName: "Full name", props: {} },
    {
      name: "email",
      props: { className: "span2" },
    },
    { name: "phone_number", props: {} },
    { name: "company_name", props: {} },
    { name: "address", props: {} },
  ];

  const checkboxes1 = [
    {
      name: "services_needed",
      caption: "Please select all applicable choices",
      options: [
        { name: "Web Development", value: "1" },
        { name: "Mobile App Development", value: "2" },
        { name: "Software Maintenance", value: "3" },
        { name: "SEO", value: "4" },
        { name: "UI/UX Design", value: "5" },
        { name: "Software Testing", value: "6" },
        { name: "Cloud Hosting and Management", value: "7" },
      ],
    },
  ];
  const checkboxes2 = [
    {
      name: "admin_site",
      displayName: "Do you need an admin site to manage the system ?",
      type: "radio",
      options: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
    },
    {
      name: "users_needed",
      caption: "Please select all applicable choices",
      options: [
        { name: "Customer", value: "1" },
        { name: "Warehouse", value: "2" },
        { name: "Admin", value: "3" },
        { name: "Supplier", value: "4" },
        { name: "Delivery", value: "5" },
        // { name: "Other", value: "other" },
      ],
    },
    {
      name: "analytics_needed",
      caption: "Please select all applicable choices",
      options: [
        { name: "Reports", value: "1" },
        { name: "Charts", value: "2" },
        // { name: "Other", value: "other" },
      ],
    },
    {
      name: "payment_gateway",
      displayName: "Do you need an integration for payment gateway?",
      type: "radio",
      options: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
    },
    {
      name: "website_exists",
      displayName: "Do you currently have an existing website?",
      type: "radio",
      options: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
    },
  ];

  return (
    <StyledSection id="quotation-form">
      <h1 className="fs57 fs24m algcenter-do">Request a Quotation</h1>
      <p className="fs30 fs14m algcenter-do">
        We would be happy to hear from you, please fill in the form below or
        mail us on
      </p>
      <p className="fs27 fs16m fw700 algcenter-do">Contact-us@spree.com.eg</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("asd");
          setSuccess(true);
        }}
      >
        <h1 className="fs27 fs12m section-title">Contact Information</h1>
        <div className="form-section form-section-1">
          {fields1.map((field) => (
            <input
              {...field.props}
              type="text"
              name={field.name}
              placeholder={field.displayName || formatString(field.name)}
              required
            />
          ))}
        </div>
        <h1 className="fs27 fs12m section-title">Project Information</h1>
        <div className="form-section form-section-2">
          {checkboxes1.map((field) => (
            <CheckSelect field={field} />
          ))}
        </div>
        <div className="form-section form-section-3">
          {checkboxes2.map((field) => (
            <CheckSelect field={field} />
          ))}
        </div>
        <div className="p-brief-container">
          <label htmlFor="p-brief" className="fs30 fs14m fw700">
            Write a brief about your project*
          </label>
          <textarea name="p_brief" id="p-brief"></textarea>
        </div>
        <Button type="submit">Get a free quotation</Button>
      </form>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  height: 155.6vw;
  background: ${colors.fadedBlue};
  padding: 4.43vw 6.2vw 0 7.76vw;
  @media (max-width: 768px) {
    height: auto;
    background: white;
    padding: 0 6.4vw 20.37vw;
  }
  form {
    margin-top: 3vw;
    @media (max-width: 768px) {
      margin-top: 6.64vw;
    }
    .span2 {
      grid-column: span 2;
    }
    .section-title {
      margin-bottom: 2vw;
    }
    input,
    textarea {
      height: 5.3vw;
      border-radius: 0.8vw;
      outline: none;
      border: solid 0.05vw #707070;
      padding-left: 2.4vw;
      @media (max-width: 768px) {
        height: 11.7vw;
        border-radius: 1.6vw;
        border-radius: solid 0.27vw #707070;
        padding-left: 4.4vw;
      }
      &::placeholder {
        font-size: 1.56vw;
        @media (max-width: 768px) {
          font-size: 3.7vw;
        }
      }
    }
    textarea {
      height: 13.6vw;
      margin-top: 1.18vw;
      padding-top: 1vw;
      @media (max-width: 768px) {
        height: 33.33vw;
        border-radius: 2.1vw;
      }
    }
    .form-section {
      margin-bottom: 3vw;
      display: flex;
      flex-direction: column;
      gap: 3vw;
      @media (max-width: 768px) {
        margin-bottom: 7.1vw;
      }
    }
    .form-section-1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.5vw;
      row-gap: 0.94vw;
      width: 100%;
      @media (max-width: 768px) {
        display: flex;
        gap: 4.3vw;
      }
    }
    .p-brief-container {
      display: flex;
      flex-direction: column;
    }
    button {
      margin-top: 6.2vw;
      /* float: right; */
    }
  }
`;

export default QuotationForm;
